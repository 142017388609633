.p-listbox {
  background: var(--fidx-color-dropdown-list-container-bg, $white);
  border-color: var(--fidx-color-form-control-border, $black-15);
  font-size: $default-font-size;
  .p-listbox-header {
    background: var(--fidx-color-dropdown-list-container-bg, $white);
    border-bottom-color: var(--fidx-color-form-control-border, $black-15);
  }
}

.p-listbox {
  .p-listbox-list {
    font-family: $default-font;
    padding-left: $primeng-listbox-list-pl;
    .p-listbox-item {
      padding: $primeng-listbox-item-padding;

      &.p-highlight {
        color: var(--fidx-color-dropdown-text, $default-light);
        background: transparent;
        font-weight: $font-weight-bold;
      }
      &:not(.p-highlight) {
        color: var(--fidx-color-dropdown-text, $default-light);
        &:not(.p-disabled):hover {
          color: var(--fidx-color-dropdown-text, $default-light);
          background: var(--fidx-color-dropdown-list-item-hover-bg, $light-light);
        }
      }
    }
  }
}

.p-listbox:not(.p-disabled) {
  .p-listbox-item {
    &:not(.p-highlight) {
      &:not(.p-disabled):hover {
        color: var(--fidx-color-dropdown-text, $default-light);
        background: var(--fidx-color-dropdown-list-item-hover-bg, $light-light);
      }
    }
  }
}

.p-listbox-filter-container {
  .p-inputtext {
    @extend .form-control !optional;
  }
  .p-listbox-filter-icon {
    top: 44%;
  }
}
