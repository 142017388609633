$list-main: ('-error', '-caution', '-success');
$var-list-main: ($negative-light, $caution-light, $positive-light);
$var-list-5: ($negative-5-light, $caution-5-light, $positive-5-light);
$var-list-25: ($negative-25-light, $caution-25-light, $positive-25-light);
$var-list-50: ($negative-50-light, $caution-50-light, $positive-50-light);

@mixin button-gradient-styles($group, $type, $bg-default, $layer-default, $border-default, $color-default) {
  background: linear-gradient(
      0deg,
      var(--fidx-color-btn-#{$group}-#{$type}-bg-grad, $bg-default),
      var(--fidx-color-btn-#{$group}-#{$type}-bg-grad, $bg-default)
    ),
    var(--fidx-color-btn-#{$group}-#{$type}-bg-layer, $layer-default);
  border-color: var(--fidx-color-btn-#{$group}-#{$type}-border, $border-default);
  color: var(--fidx-color-btn-#{$group}-text, $color-default);
}

@mixin primary-button-style(
  $group,
  $main-default,
  $box-default,
  $bg-default,
  $gradient-layer-default,
  $border-default,
  $color-default,
  $disable-default
) {
  background: var(--fidx-color-btn-#{$group}-bg, $main-default);
  border-color: var(--fidx-color-btn-#{$group}-border, $main-default);
  color: var(--fidx-color-btn-#{$group}-text, $color-default);
  font-weight: var(--fidx-color-btn-#{$group}-weight, $font-weight-400);
  margin: $btn-margin-all;

  &:focus {
    background: var(--fidx-color-btn-#{$group}-active-bg-layer, $main-default);
    border-color: var(--fidx-color-btn-#{$group}-active-border, $main-default);
    box-shadow: $btn-box-shadow-values var(--fidx-color-btn-#{$group}-boxshadow, $box-default);
  }

  &:hover {
    @include button-gradient-styles($group, 'hover', $bg-default, $gradient-layer-default, $border-default, $color-default);
  }

  &:disabled,
  &.disabled {
    background: var(--fidx-color-btn-#{$group}-disabled-bg, $disable-default);
    border-color: var(--fidx-color-btn-#{$group}-disabled-border, $disable-default);
    color: var(--fidx-color-btn-#{$group}-text, $color-default);
  }
}

.btn {
  font-family: $default-font;
  font-size: $btn-text-md;

  &:not(:disabled):hover {
    box-shadow: $btn-box-shadow-default;
  }

  &:disabled,
  &.disabled {
    pointer-events: initial;
    cursor: no-drop;
    opacity: 0.84;
  }

  &-small {
    font-size: $btn-text-sm;
    line-height: $btn-lh-sm;
    height: $btn-height-sm;
    border-radius: $btn-border-radius;
    padding: $btn-padding-y-sm $btn-padding-x-sm;
  }

  &-large {
    font-size: $btn-text-lg;
    line-height: $btn-lh-lg;
    height: $btn-height-lg;
    border-radius: $btn-border-radius;
    padding: $btn-padding-y-lg $btn-padding-x-lg;
  }

  &:first-child {
    &:active {
      &.btn-primary {
        @include button-gradient-styles('primary', 'active', $black-5, $action-light, $action-light, $white);
        @each $action in $list-main {
          $i: index($list-main, $action);

          &.btn#{$action} {
            &:active {
              @include button-gradient-styles(
                'primary#{$action}',
                'active',
                $black-5,
                nth($var-list-main, $i),
                nth($var-list-main, $i),
                $white
              );
            }
          }
        }
      }

      &.btn-secondary {
        @include button-gradient-styles('secondary', 'active', $action-10-light, $bg-default-light, $action-light, $action-light);

        @each $action in $list-main {
          $i: index($list-main, $action);

          &.btn#{$action} {
            @include button-gradient-styles(
              'secondary#{$action}',
              'active',
              nth($var-list-25, $i),
              $bg-default-light,
              nth($var-list-main, $i),
              nth($var-list-main, $i)
            );
          }
        }
      }

      &.btn-text {
        &:active {
          color: var(--fidx-color-btn-textbtn-text, $default-light);
          background: var(--fidx-color-btn-textbtn-bg, transparent);
          border-color: var(--fidx-color-form-control-border, $black-15);
        }
      }
    }
  }
}

.btn-primary {
  @include primary-button-style(
    'primary',
    $action-light,
    $action-25-light,
    $black-5,
    $action-light,
    $action-light,
    $white,
    $action-50-light
  );

  @each $action in $list-main {
    $i: index($list-main, $action);

    &.btn#{$action} {
      @include primary-button-style(
        'primary#{$action}',
        nth($var-list-main, $i),
        nth($var-list-25, $i),
        $black-5,
        nth($var-list-main, $i),
        nth($var-list-main, $i),
        $white,
        nth($var-list-50, $i)
      );
    }
  }
}

.btn-secondary {
  background: var(--fidx-color-btn-secondary-bg, $bg-default-light);
  border-color: var(--fidx-color-btn-secondary-border, $black-15);
  color: var(--fidx-color-btn-secondary-text, $action-light);
  font-weight: var(--fidx-color-btn-secondary-weight, $font-weight-normal);
  margin: $btn-margin-all;

  &:hover {
    @include button-gradient-styles('secondary', 'hover', $action-5-light, $bg-default-light, $black-15, $action-light);
  }

  &:active {
    @include button-gradient-styles('secondary', 'active', $action-10-light, $bg-default-light, $action-light, $action-light);
    &:focus {
      box-shadow: $btn-box-shadow-values var(--fidx-color-btn-secondary-focus-boxshadow, $action-25-light);
    }
  }

  &:focus {
    background: var(--fidx-color-btn-secondary-active-bg-layer, $bg-default-light);
    border-color: var(--fidx-color-btn-secondary-active-border, $action-light);
    box-shadow: $btn-box-shadow-values var(--fidx-color-btn-secondary-focus-boxshadow, $action-25-light);
    color: var(--fidx-color-btn-secondary-text, $action-light);
  }

  &:disabled,
  &.disabled {
    opacity: 0.63;
    color: var(--fidx-color-btn-secondary-text, $action-light);
    background: var(--fidx-color-btn-secondary-bg, $bg-default-light);
    border-color: var(--fidx-color-btn-secondary-border, $black-15);
  }

  @each $action in $list-main {
    $i: index($list-main, $action);
    &.btn#{$action} {
      border-color: var(--fidx-color-btn-secondary#{$action}-border, nth($var-list-50, $i));
      color: var(--fidx-color-btn-secondary#{$action}-text, nth($var-list-main, $i));
      font-weight: var(--fidx-color-btn-secondary#{$action}-weight, $font-weight-normal);
      margin: $btn-margin-all;

      &:hover {
        @include button-gradient-styles(
          'secondary#{$action}',
          'hover',
          nth($var-list-5, $i),
          $bg-default-light,
          nth($var-list-25, $i),
          nth($var-list-main, $i)
        );
      }

      &:focus {
        background: var(--fidx-color-btn-secondary#{$action}-active-bg-layer, $bg-default-light);
        border-color: var(--fidx-color-btn-secondary#{$action}-active-border, nth($var-list-main, $i));
        box-shadow: $btn-box-shadow-values var(--fidx-color-btn-secondary#{$action}-focus-boxshadow, nth($var-list-25, $i));
        color: var(--fidx-color-btn-secondary#{$action}-text, nth($var-list-main, $i));
      }

      &:disabled,
      &.disabled {
        opacity: 0.63;
        color: var(--fidx-color-btn-secondary#{$action}-text, nth($var-list-main, $i));
        background: var(--fidx-color-btn-secondary-bg, $bg-default-light);
        border-color: var(--fidx-color-btn-secondary#{$action}-border, nth($var-list-50, $i));
      }
    }
  }
}

.btn-text {
  background: var(--fidx-color-btn-textbtn-bg, transparent);
  border-color: var(--fidx-color-btn-textbtn-border, transparent);
  color: var(--fidx-color-btn-textbtn-text, $default-light);
  font-weight: var(--fidx-color-btn-textbtn-weight, $font-weight-bold);
  margin: $btn-margin-all;
}

:not(.btn-check) {
  + .btn:active {
    &.btn-secondary {
      @include button-gradient-styles('secondary', 'active', $action-10-light, $bg-default-light, $action-light, $action-light);

      @each $action in $list-main {
        $i: index($list-main, $action);
        &.btn#{$action} {
          @include button-gradient-styles(
            'secondary#{$action}',
            'active',
            nth($var-list-25, $i),
            $bg-default-light,
            nth($var-list-main, $i),
            nth($var-list-main, $i)
          );
        }
      }
    }
  }

  + .btn.btn-primary {
    &:not(:disabled) {
      &:active {
        @include button-gradient-styles('primary', 'active', $black-5, $action-light, $action-light, $white);
      }
      &:hover {
        @include button-gradient-styles('primary', 'hover', $black-5, $action-light, $action-light, $white);
      }
      @each $action in $list-main {
        $i: index($list-main, $action);
        &.btn#{$action} {
          &:active {
            @include button-gradient-styles(
              'primary#{$action}',
              'active',
              $black-5,
              nth($var-list-main, $i),
              nth($var-list-main, $i),
              $white
            );
          }
          &:hover {
            @include button-gradient-styles(
              'primary#{$action}',
              'hover',
              $black-5,
              nth($var-list-main, $i),
              nth($var-list-main, $i),
              $white
            );
          }
        }
      }
    }
    &:disabled {
      &:active {
        background: var(--fidx-color-btn-primary-disabled-bg, $action-50-light);
        border-color: var(--fidx-color-btn-primary-disabled-border, $action-50-light);
        color: var(--fidx-color-btn-primary-text, $white);
      }
      @each $action in $list-main {
        $i: index($list-main, $action);
        &.btn#{$action} {
          background: var(--fidx-color-btn-primary#{$action}-disabled-bg, nth($var-list-50, $i));
          border-color: var(--fidx-color-btn-primary#{$action}-disabled-border, nth($var-list-50, $i));
          color: var(--fidx-color-btn-primary-text, $white);
        }
      }
    }
  }

  + .btn.btn-secondary {
    &:not(:disabled) {
      &:hover {
        @include button-gradient-styles('secondary', 'hover', $action-5-light, $bg-default-light, $black-15, $action-light);
      }

      @each $action in $list-main {
        $i: index($list-main, $action);
        &.btn#{$action} {
          &:hover {
            @include button-gradient-styles(
              'secondary#{$action}',
              'hover',
              nth($var-list-5, $i),
              $bg-default-light,
              nth($var-list-25, $i),
              nth($var-list-main, $i)
            );
          }
        }
      }
    }
    &:disabled {
      &:active {
        background: var(--fidx-color-btn-secondary-bg, $bg-default-light);
        border-color: var(--fidx-color-btn-secondary-border, $black-15);
        color: var(--fidx-color-btn-secondary-text, $action-light);

        @each $action in $list-main {
          $i: index($list-main, $action);
          &.btn#{$action} {
            background: var(--fidx-color-btn-secondary#{$action}-bg, $bg-default-light);
            border-color: var(--fidx-color-btn-secondary#{$action}-border, nth($var-list-main, $i));
            color: var(--fidx-color-btn-secondary#{$action}-text, nth($var-list-main, $i));
          }
        }
      }
    }
  }
}
