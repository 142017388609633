@mixin icon-override_fa($icon) {
  font-family: 'Font Awesome 6 Pro';
  font-size: $form-toggle-icon-size;
  font-weight: $form-toggle-font-weight;
  line-height: $form-toggle-line-height;
  display: inline-block;
  -webkit-font-smoothing: antialiased;
  text-align: center;

  &::before {
    content: $icon;
  }
}

.p-inputswitch {
  width: $form-toggle-width;
  height: $form-toggle-height;
  .p-inputswitch-slider {
    background-color: var(--fidx-color-toggle-knob-bg, $white) !important;
    color: var(--fidx-color-toggle-knob-text, $black-55);
    border: $form-toggle-border solid var(--fidx-color-toggle-before-border, $black-15);
    @include icon-override_fa($form-toggle-icon-off);

    &::before {
      background-color: var(--fidx-color-toggle-knob-bg, $white);
      box-shadow: 0px 2px 4px var(--fidx-color-toggle-knob-boxshadow, $black-25);
      left: 0px !important;
    }
  }
  &.p-inputswitch-checked .p-inputswitch-slider {
    background: var(--fidx-color-toggle-checked-bg, $action-light) !important;
    color: var(--fidx-color-toggle-knob-checked-text, $action-light);
    @include icon-override_fa($form-toggle-icon-on);

    &::before {
      background-color: var(--fidx-color-toggle-knob-checked-bg, $white);
      transform: translateX($form-toggle-translate-x) !important;
    }
  }
}
