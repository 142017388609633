p-multiselect {
  &:not(.p-inputwrapper-focus) {
    span.p-multiselect-trigger-icon {
      transition: transform 250ms;
      transform: rotate(0deg) translateY(0px);
    }
  }
  &.p-inputwrapper-focus {
    span.p-multiselect-trigger-icon {
      transition: transform 250ms;
      transform: rotate(180deg) translateY(2px);
    }
  }
}

.p-multiselect {
  background-color: var(--fidx-color-form-control-bg, $bg-default-light);
  color: var(--fidx-color-dropdown-text, $default-light);
  border: $form-border-size solid var(--fidx-color-form-control-border, $black-15);
  &.form-control {
    display: inline-flex;
    padding: $primeng-multiselect-control-padding;
  }
  &:not(.p-disabled) {
    &:hover {
      border: $form-border-size solid var(--fidx-color-form-control-hover-border, $action-light);
    }
  }
  .p-inputtext {
    color: var(--fidx-color-dropdown-text, $default-light);
    font-family: $default-font;
    font-size: $default-font-size;
    line-height: $primeng-dropdown-input-line-height;
    padding: $primeng-dropdown-input-padding;
  }
  .p-multiselect-trigger {
    width: $primeng-dropdown-trigger-width;
    font-size: $default-font-size;
    color: var(--fidx-color-dropdown-trigger, $black-15);
    &[aria-expanded='true'] {
      span.p-multiselect-trigger-icon {
        transition: transform 250ms;
        transform: rotate(180deg) translateY(2px);
      }
    }
    &[aria-expanded='false'] {
      span.p-multiselect-trigger-icon {
        transition: transform 250ms;
        transform: rotate(0deg) translateY(0px);
      }
    }
  }

  .p-multiselect-label {
    padding: $primeng-multiselect-label-padding;
    &.p-placeholder {
      color: var(--fidx-color-form-placeholder, $black-55);
    }
  }
}

.p-multiselect-items-wrapper {
  background: var(--fidx-color-dropdown-list-container-bg, $white);
  color: var(--fidx-color-dropdown-text, $default-light);
  border-bottom-left-radius: $form-border-radius;
  border-bottom-right-radius: $form-border-radius;
}

.p-multiselect-panel {
  background: var(--fidx-color-dropdown-list-container-bg, $white);
  .p-multiselect-header {
    padding: $primeng-multiselect-panel-header-padding;
    background: var(--fidx-color-dropdown-list-container-bg, $white);
    border-bottom-color: var(--fidx-color-form-control-border, $black-15);
  }
  .p-multiselect-items {
    font-family: $default-font;
    font-size: $default-font-size;
    padding-left: $primeng-dropdown-items-pl;
    .p-multiselect-item {
      padding: $primeng-dropdown-item-padding;

      &.p-highlight {
        color: var(--fidx-color-dropdown-text, $default-light);
        background: transparent;
        font-weight: $font-weight-bold;
        &.p-focus {
          background: var(--fidx-color-dropdown-list-item-hover-bg, $light-light);
        }
      }

      &:not(.p-highlight) {
        color: var(--fidx-color-dropdown-text, $default-light);
        .template {
          padding-left: $primeng-dropdown-item-not-highlight-pl;
        }
        &:not(.p-disabled) {
          &.p-focus {
            background: var(--fidx-color-dropdown-list-item-hover-bg, $light-light);
          }
          &:hover {
            color: var(--fidx-color-dropdown-text, $default-light);
            background: var(--fidx-color-dropdown-list-item-hover-bg, $light-light);
          }
        }
      }
    }
  }
}

.p-multiselect-filter-container {
  .p-inputtext {
    @extend %form-control-styles !optional;
    padding-right: $primeng-multiselect-filter-container-pr;
    margin-bottom: $primeng-multiselect-filter-container-mb;
    margin-right: $primeng-multiselect-filter-container-mr;
  }
  .p-multiselect-filter-icon {
    position: relative;
    right: $primeng-dropdown-filter-icon-right;
  }
}

.p-element.ng-touched.ng-invalid {
  .p-multiselect {
    @extend .has-error !optional;
    padding-left: $primeng-multiselect-error-pl;
    @extend %FIDx-has-error-attributes !optional;

    .p-multiselect-label {
      padding-top: $primeng-multiselect-error-label-pt;
    }
  }
}
