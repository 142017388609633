/* ALL LATO STYLES */
$font-base-path: '' !default;
$lato: 'Lato', sans-serif;
$default-font: $lato;

@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,600;0,700;1,100;1,300;1,400;1,600;1,700&display=swap');

@mixin lato_font() {
  @font-face {
    font-family: 'Lato';
    font-style: italic;
    font-weight: 100;
    font-display: swap;
    src: url('../../../fonts/Lato/Lato-ThinItalic.ttf') format('truetype');
  }

  @font-face {
    font-family: 'Lato';
    font-style: italic;
    font-weight: 300;
    font-display: swap;
    src: url('../../../fonts/Lato/Lato-LightItalic.ttf') format('truetype');
  }

  @font-face {
    font-family: 'Lato';
    font-style: italic;
    font-weight: 400;
    font-display: swap;
    src: url('../../../fonts/Lato/Lato-RegularItalic.ttf') format('truetype');
  }

  @font-face {
    font-family: 'Lato';
    font-style: italic;
    font-weight: 700;
    font-display: swap;
    src: url('../../../fonts/Lato/Lato-BoldItalic.ttf') format('truetype');
  }

  @font-face {
    font-family: 'Lato';
    font-style: italic;
    font-weight: 900;
    font-display: swap;
    src: url('../../../fonts/Lato/Lato-BlackItalic.ttf') format('truetype');
  }

  @font-face {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 100;
    font-display: swap;
    src: url('../../../fonts/Lato/Lato-Thin.ttf') format('truetype');
  }

  @font-face {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url('../../../fonts/Lato/Lato-Light.ttf') format('truetype');
  }

  @font-face {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url('../../../fonts/Lato/Lato-Regular.ttf') format('truetype');
  }

  @font-face {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url('../../../fonts/Lato/Lato-Bold.ttf') format('truetype');
  }

  @font-face {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 900;
    font-display: swap;
    src: url('../../../fonts/Lato/Lato-Black.ttf') format('truetype');
  }
}

@include lato_font();
