.form-control {
  @extend %form-control-styles !optional;
}

input.form-control {
  &.ng-touched.ng-invalid {
    @extend .has-error !optional;
    @extend %FIDx-has-error-attributes !optional;
  }
  &:read-only {
    background: var(--fidx-color-form-control-readonly-bg, $bg-dark-light);
    &:hover {
      background: var(--fidx-color-form-control-readonly-bg, $bg-dark-light);
      border: $form-border-size solid var(--fidx-color-form-control-readonly-border, $black-55);
    }
    &:focus-visible {
      outline: 0;
      border: $form-border-size-alert solid var(--fidx-color-form-control-readonly-border, $black-55);
    }
  }
  &.icon-left {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
  }
}

textarea.form-control {
  &.ng-touched.ng-invalid {
    @extend .has-error !optional;
    @extend %FIDx-has-error-attributes !optional;
  }
}

.p-inputgroup-addon.form-control-icon {
  height: $form-control-height;
  background-color: var(--fidx-color-form-control-bg, $bg-default-light);
  border: $form-border-size solid var(--fidx-color-form-control-border, $black-15);
  &.icon-left {
    border-right: none;
  }
}

p-inputmask.p-element,
p-inputnumber.p-element {
  &.ng-touched.ng-invalid {
    .p-inputtext {
      @extend .has-error !optional;
      @extend %FIDx-has-error-attributes !optional;
    }
  }
}

.p-input-icon-right.f-w i.pi {
  top: 45%;
}

.p-input-icon-right.f-w i.fa-solid {
  top: 44%;
}
