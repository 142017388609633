// SELECTORS

html,
body {
  height: $full-height;
  margin: 0;
}

body {
  font-family: $default-font;
  font-size: $default-font-size;
  line-height: $body-line-height;
  color: var(--fidx-color-text-default, $default-light);
}

a,
.span-link {
  color: var(--fidx-color-text-link, $link-color-light);
  text-decoration: none;
  cursor: pointer;
  &:hover {
    color: var(--fidx-color-text-link, $link-color-light);
    text-decoration: underline;
  }
}

::placeholder {
  font-size: $default-font-size;
  color: var(--fidx-color-form-placeholder, $black-55) !important;
}

::-ms-reveal {
  display: none;
}

// COMMON STYLES
.cursor {
  cursor: pointer;
}

.disabled {
  opacity: 0.5;
  cursor: no-drop;
}

.cursor-text {
  cursor: text !important;
}

.flex-reverse {
  display: inline-flex;
  flex-direction: column-reverse;
}

.card.box-shadow {
  box-shadow:
    $card-box-shadow-global-1 rgb(var(--fidx-color-shadow-num, $black-num) / 20%),
    $card-box-shadow-global-2 rgb(var(--fidx-color-shadow-num, $black-num) / 14%),
    $card-box-shadow-global-3 rgb(var(--fidx-color-shadow-num, $black-num) / 12%);
}

.card-sub-header {
  font-size: $medium-font-size;
  line-height: $medium-line-height;
  font-weight: $font-weight-bold;
  color: var(--fidx-color-utility-action, $action-light);
  margin-bottom: $spacing-sm;
}

.card {
  &-m {
    &-b {
      margin-bottom: $card-margin-spacing;
    }
    &-t {
      margin-top: $card-margin-spacing;
    }
    &-l {
      margin-left: $card-margin-spacing;
    }
    &-r {
      margin-right: $card-margin-spacing;
    }
  }
}

.f-w {
  width: $full-width;
}

.bold {
  font-weight: $font-weight-bold;
}

.utility-action {
  color: var(--fidx-color-utility-action, $action-light);
}
