.p-accordion {
  font-family: $default-font;
  font-size: $default-font-size;

  .p-accordion-header {
    font-size: $medium-font-size;
    .p-accordion-header-link {
      display: flex;
      flex-flow: row-reverse;
      justify-content: space-between;
      background: var(--fidx-color-card-bg, $white);
      border-color: var(--fidx-color-card-border, $black-15);
      border-bottom: none;
      color: var(--fidx-color-text-default, $default-light);

      &:hover {
        text-decoration: none;
      }
    }

    a.p-accordion-header-link {
      &[aria-expanded='true'] {
        span.accordion-angle-icon {
          transition: transform 250ms;
          transform: rotate(180deg) translateY(2px);
        }
      }
      &[aria-expanded='false'] {
        span.accordion-angle-icon {
          transition: transform 250ms;
          transform: rotate(0deg) translateY(0px);
        }
      }

      .p-accordion-toggle-icon {
        display: none;
      }
    }

    &:not(.p-highlight):not(.p-disabled) {
      &:hover .p-accordion-header-link {
        border-color: var(--fidx-color-card-border, $black-15);
        color: var(--fidx-color-text-default, $default-light);
        background: var(--fidx-color-grid-row-hover-bg, $bg-dark-light);
      }
    }
    &:not(.p-disabled).p-highlight {
      .p-accordion-header-link {
        background: var(--fidx-color-card-bg, $white);
        border-color: var(--fidx-color-card-border, $black-15);
        color: var(--fidx-color-text-default, $default-light);
      }
      &:hover .p-accordion-header-link {
        border-color: var(--fidx-color-card-border, $black-15);
        color: var(--fidx-color-text-default, $default-light);
        background: var(--fidx-color-grid-row-hover-bg, $bg-dark-light);
      }
    }
  }

  .p-accordion-content {
    background: var(--fidx-color-card-bg, $white);
    color: var(--fidx-color-text-default, $default-light);
    border-color: var(--fidx-color-card-border, $black-15);
  }
}
