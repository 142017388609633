.p-radiobutton {
  width: $primeng-radio-width;
  height: $primeng-radio-height;

  .p-radiobutton-box {
    width: $primeng-radio-width;
    height: $primeng-radio-height;
    background: var(--fidx-color-form-check-radio-bg, $bg-default-light);
    border-color: var(--fidx-color-form-check-radio-border, $black-15);

    &:not(.p-disabled) {
      &.p-focus {
        border-color: var(--fidx-color-form-check-radio-border-focus, $black-15);
        box-shadow: $primeng-radio-box-shadow var(--fidx-color-form-check-radio-focus-boxshadow, $action-25-light);
      }
      &:not(.p-highlight) {
        &:hover {
          border-color: var(--fidx-color-utility-action, $action-light);
        }
      }
    }

    &.p-highlight {
      border-color: var(--fidx-color-form-check-radio-checked-border, $action-light);
      background: var(--fidx-color-form-check-radio-checked-bg, $action-light);

      &:not(.p-disabled) {
        &:hover {
          border-color: var(--fidx-color-form-check-radio-checked-border, $action-light);
          background: var(--fidx-color-form-check-radio-checked-bg, $action-light);
        }
      }
      .p-radiobutton-icon {
        background-color: var(--fidx-color-form-check-radio-bg, $bg-default-light);
      }
    }
  }
}

.p-element.ng-touched.ng-invalid {
  .p-radiobutton-box {
    border-color: var(--fidx-color-error, $negative-light);
    @extend %FIDx-has-error-attributes !optional;
    &:not(.p-disabled) {
      &:not(.p-highlight) {
        &:hover {
          border-color: var(--fidx-color-error, $negative-light);
        }
      }
    }
  }
}
