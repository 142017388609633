.p-button {
  padding: $primeng-btn-padding;
  &.p-button-icon-only {
    padding: $primeng-btn-padding-icon;
  }
  &.p-button-loading .p-button-icon-left {
    margin-top: $primeng-btn-loading-mt;
    margin-right: $primeng-btn-loading-mr;
  }
}

.p-splitbutton-menubutton {
  background: var(--fidx-color-utility-action, $action-light);
  border-color: var(--fidx-color-utility-action, $action-light);
  color: var(--fidx-color-btn-primary-text, $white);

  &:hover,
  &:enabled:hover {
    background: linear-gradient(
        0deg,
        var(--fidx-color-btn-primary-hover-bg-grad, $black-5),
        var(--fidx-color-btn-primary-hover-bg-grad, $black-5)
      ),
      var(--fidx-color-btn-primary-hover-bg-layer, $action-light);
    border-color: var(--fidx-color-btn-primary-hover-border, $action-light);
    color: var(--fidx-color-btn-primary-text, $white);
  }
}

.p-selectbutton {
  .p-button {
    background-color: var(--fidx-color-btn-secondary-bg, $bg-default-light);
    border-color: var(--fidx-color-btn-secondary-border, $black-15);
    color: var(--fidx-color-btn-secondary-text, $action-light);
    font-family: $default-font;
    font-size: $default-font-size;
    font-weight: var(--fidx-color-btn-secondary-weight, $font-weight-normal);

    .p-button-label {
      font-weight: $font-weight-normal;
    }

    &:not(.p-disabled):not(.p-highlight):hover {
      background: linear-gradient(
          0deg,
          var(--fidx-color-btn-secondary-hover-bg-grad, $action-5-light),
          var(--fidx-color-btn-secondary-hover-bg-grad, $action-5-light)
        ),
        var(--fidx-color-btn-secondary-hover-bg-layer, $bg-default-light);
      border-color: var(--fidx-color-btn-secondary-hover-border, $black-15);
      color: var(--fidx-color-btn-secondary-text, $action-light);
    }

    &.p-highlight {
      background-color: var(--fidx-color-btn-primary-bg, $action-light);
      border-color: var(--fidx-color-btn-primary-bg, $action-light);
      color: var(--fidx-color-btn-primary-text, $white);

      .p-button-label {
        font-weight: $font-weight-bold;
      }

      &:hover {
        background: linear-gradient(
            0deg,
            var(--fidx-color-btn-primary-hover-bg-grad, $black-5),
            var(--fidx-color-btn-primary-hover-bg-grad, $black-5)
          ),
          var(--fidx-color-btn-primary-hover-bg-layer, $action-light);
        border-color: var(--fidx-color-btn-primary-hover-border, $action-light);
        color: var(--fidx-color-btn-primary-text, $white);
      }
    }
  }
}
