.product-training {
  &-section {
    padding: $product-training-padding;
    background-color: $product-training-color-background; //var(--fidx-color-warning, $caution-light);
    border-radius: $form-border-radius;
    margin-bottom: $product-training-mb;
  }
  &-header {
    font-size: $large-font-size;
    font-weight: $font-weight-bold;
    margin-bottom: $product-training-header-mb;
  }
}
