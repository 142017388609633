#FIDxWrapper {
  background: var(--fidx-color-body-bg, $bg-dark-light);
  display: flex;
  flex-direction: column;
  height: $full-height;
}

#contentWrapper {
  flex: 1 1 auto;
  position: relative;
  overflow-y: auto;
}

.main-panel {
  flex: 1 1 auto;
  position: relative;
  overflow-y: auto;
  width: $full-width;

  &[data='red'] {
    border-color: $danger;
  }

  &.home {
    padding-top: 0px;
  }

  > .navbar {
    margin-bottom: 0;
  }

  .content {
    min-height: calc(100vh - 85px);

    &.bc {
      min-height: calc(100vh - var(--container-offset, 206px));
      &.no-nav {
        min-height: calc(100vh - 152px);
      }
    }
    &.footer-only {
      min-height: calc(100vh - 84px);
    }
  }

  .header {
    margin-bottom: 50px;
  }

  .fixed-plugin .dropdown-toggle:after {
    display: none;
  }
}

.sticky-button-container {
  position: sticky;
  bottom: 0px;
  background-color: var(--fidx-color-form-button-component-footer-bg, $white);
  box-shadow: $bottom-button-container-box-shadow var(--fidx-color-form-button-component-footer-boxshadow, $black-10);
}

.content {
  &.bc {
    padding-top: $spacing-sm;
    padding-bottom: $spacing-sm;
  }
}

.loading-buttons-container {
  display: inline-flex;
  vertical-align: middle;
}

// OTHER LAYOUT - These could be removed at some point

.belowCard {
  width: $full-width;
  z-index: 10;
  border: none !important;
}

.dashboard-charts canvas {
  margin: auto !important;
}

.letter-spacing-2 {
  letter-spacing: 2px;
  font-weight: 540;
  text-transform: uppercase;
}

// .border-dashed {
//   border: $form-border-size dashed white;
// }

.anchorTag {
  color: $primary !important;
  text-decoration: none;
}

.anchorTag:hover {
  text-decoration: underline !important;
}

small .pi {
  font-size: 90%;
}

// MEDIA QUERIES
@media (min-width: 991px) {
  .main-panel {
    -webkit-transition-property: top, bottom, width;
    transition-property: top, bottom, width;
    -webkit-transition-duration: 0.2s, 0.2s, 0.35s;
    transition-duration: 0.2s, 0.2s, 0.35s;
    -webkit-transition-timing-function: linear, linear, ease;
    transition-timing-function: linear, linear, ease;
    -webkit-overflow-scrolling: touch;
  }

  .navbar-minimize {
    button {
      margin-left: $navbar-button-ml;

      i {
        color: $white;
        font-size: $large-font-size;
      }
    }
  }
}

@media screen and (max-width: 991px) {
  .main-panel {
    width: $full-width;
  }

  .nav-open {
    .main-panel {
      right: 0;
    }

    body {
      position: relative;
      overflow-x: hidden;
    }
  }
}

@media screen and (max-width: 767px) {
  .main-panel .content {
    padding-left: 15px;
    padding-right: 15px;
  }
}
