/* You can add global styles to this file, and also import other style files */

// BEGIN WITH THE CORE STYLES
// These include variables, themes and other common initial styles
@import 'core/index';

// ELEMENT STYLES NEXT

// Button styles
@import 'buttons/index';

// Forms and controls (can contain buttons)
@import 'forms/index';

// Cards, can contain forms and buttons
@import 'cards/index';

// Favorites
@import 'favorites/index';

// AFTER ELEMENTS, PRIMENG OVERRIDES
@import 'primeng/index';

// GLOBAL STYLES
// These are main website styles across components
@import 'global/index';

// STYLES COMING FROM PRIMENG THEME
// Will plan to remove once we have established styles and don't need the theme.
.pi {
  font-size: initial;
}

.p-dropdown:not(.p-disabled):hover {
  border-color: var(--fidx-color-form-control-hover-border, $action-light);
}

.p-dropdown-filter-container .p-dropdown-filter-icon {
  right: 0px !important;
}

.p-input-icon-left {
  > i {
    margin-top: $primeng-input-icon-left-mt;
    color: var(--fidx-color-text-default, $default-light);
  }
  .form-control {
    padding-left: $primeng-input-icon-form-control-pl;
  }
}

.p-inputtext {
  &:enabled {
    &:hover {
      border-color: var(--fidx-color-form-control-hover-border, $action-light);
    }
  }
}

.p-buttonset:not(.p-splitbutton) .p-button {
  font-size: $default-font-size;
  font-weight: $font-weight-normal;
}
