@media (max-width: 374px) {
  .pt-xs-mobilescreen-4 {
    padding-top: 32px;
  }
}

@media (max-width: 431px) {
  .mt-xs-mobilescreen-4 {
    margin-top: 32px;
  }
}

@media (max-width: 575px) {
  .px-sm-mobilescreen-0-575 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .text-xs-center {
    text-align: center !important;
  }
}

@media screen and (max-width: 767px) {
  .px-sm-mobilescreen-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .p-dialog .p-dialog-content {
    padding: 0 8px !important;
  }

  .p-paginator .p-paginator-current {
    min-width: inherit !important;
    height: inherit !important;
    padding: initial !important;
    font-size: $default-font-size !important;
  }

  .p-paginator .p-paginator-first,
  .p-paginator .p-paginator-prev,
  .p-paginator .p-paginator-next,
  .p-paginator .p-paginator-last {
    min-width: inherit !important;
    height: inherit !important;
  }

  .p-paginator .p-paginator-pages .p-paginator-page {
    min-width: inherit !important;
    height: inherit !important;
  }

  .p-paginator .p-dropdown {
    margin-left: inherit !important;
    margin-right: inherit !important;
    height: inherit !important;
    margin: $primeng-pagination-dropdown-margin !important;
  }

  .p-paginator {
    padding: $primeng-pagination-links-padding !important;
  }
}

@media screen and (max-width: 991px) {
  .p-table-search {
    width: 160px;
  }

  .p-dialog-width {
    width: 95% !important;
    min-width: 300px;
  }
}

@media screen and (min-width: 991px) {
  .p-dialog-width {
    width: 50vw;
    max-width: 600px;
  }

  .pt-lg-largescreen-3 {
    margin-top: 17.6px !important;
  }

  .pe-lg-largescreen-1 {
    padding-right: 4px !important;
  }

  .pl-lg-largescreen-1 {
    padding-left: 4px !important;
  }

  .pl-lg-largescreen-2 {
    padding-left: 48px !important;
    margin-left: 48px !important;
  }
}

@media screen and (max-width: 640px) {
  :host ::ng-deep {
    .p-datatable {
      &.p-datatable-responsive-demo {
        .p-datatable-thead > tr > th,
        .p-datatable-tfoot > tr > td {
          display: none !important;
        }

        .p-datatable-tbody > tr > td {
          text-align: left;
          display: block;
          width: $full-width;
          float: left;
          clear: left;
          border: $primeng-table-responsive-column-border;

          .p-column-title {
            padding: $primeng-table-responsive-column-title-padding;
            min-width: 30%;
            display: inline-block;
            margin: $primeng-table-responsive-column-title-margin;
            font-weight: bold;
          }

          &:last-child {
            border-bottom: $form-border-size solid var(--surface-d);
          }
        }
      }
    }
  }
}
