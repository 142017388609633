.col-form-label {
  font-size: $default-font-size;
  font-weight: $font-weight-strong;
  padding: $form-label-padding;

  &.inline {
    padding: $form-label-padding-inline;
  }
}

.form-required {
  color: var(--fidx-color-required, $negative-light);
  &::after {
    content: '*';
  }
}

.form-label-required:after {
  content: '*';
  color: var(--fidx-color-required, $negative-light);
}

.form-label-input-full {
  display: inline-block;
  width: $full-width;
}

.special-label-info {
  background-color: var(--fidx-color-utility-info, $info-light);
  color: $white;
  font-size: $small-font-size;
  font-weight: $font-weight-bold;
  min-width: 43px;
  text-align: center;
  white-space: nowrap;
  margin-top: 0px;
  padding: $spacing-xs 6px;
  border-radius: 6px;
}

.special-label-positive {
  background-color: var(--fidx-color-utility-positive, $positive-light);
  color: $white;
  font-size: $small-font-size;
  font-weight: $font-weight-bold;
  min-width: 43px;
  text-align: center;
  white-space: nowrap;
  margin-top: 0px;
  padding: $spacing-xs 6px;
  border-radius: 6px;
}

.special-label-affirmation {
  background-color: var(--fidx-color-utility-affirmation, $affirmation-light);
  color: $white;
  font-size: $small-font-size;
  font-weight: $font-weight-bold;
  min-width: 43px;
  text-align: center;
  white-space: nowrap;
  margin-top: 0px;
  padding: $spacing-xs 6px;
  border-radius: 6px;
}

.special-label-negative {
  background-color: var(--fidx-color-utility-negative, $negative-light);
  color: $white;
  font-size: $small-font-size;
  font-weight: $font-weight-bold;
  min-width: 43px;
  text-align: center;
  white-space: nowrap;
  margin-top: 0px;
  padding: $spacing-xs 6px;
  border-radius: 6px;
}
