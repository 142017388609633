.p-dialog {
  font-family: $default-font;
  font-size: $default-font-size;
  background-color: var(--fidx-color-dialog-content-bg, $bg-default-light);
  box-shadow: $primeng-dialog-box-shadow var(--fidx-color-dialog-content-boxshadow, $black-8);
  padding: $primeng-dialog-padding !important;

  .p-dialog-header {
    padding: $primeng-dialog-padding-header;
    background-color: var(--fidx-color-dialog-content-bg, $bg-default-light);
    color: var(--fidx-color-dialog-content-text, $default-light);
    font-size: $primeng-dialog-font-header !important;
    font-weight: $font-weight-bold;

    .dialog-icon {
      font-size: $primeng-dialog-icon-header-size !important;
      color: var(--fidx-color-dialog-icon-triangle-text, $caution-light);

      &.error {
        color: var(--fidx-color-error, $negative-light);
      }
    }
  }

  .p-dialog-content {
    padding: $primeng-dialog-content-padding;
    background-color: var(--fidx-color-dialog-content-bg, $bg-default-light);
    color: var(--fidx-color-dialog-content-text, $default-light);

    .scrollable-body {
      max-height: $primeng-dialog-content-max-height;
      overflow-y: auto;
      scrollbar-width: thin;
    }

    .dialog-icon {
      font-size: $primeng-dialog-content-icon-header-size;
      color: var(--fidx-color-dialog-icon-triangle-text, $caution-light);
    }
  }

  .p-dialog-footer {
    background-color: var(--fidx-color-dialog-content-bg, $bg-default-light);
  }
}

.p-dialog-popup-width {
  width: $primeng-dialog-popup-width;
}
