.standard-spinner {
  .p-progress-spinner-circle {
    animation: p-progress-spinner-dash 1.5s ease-in-out infinite, custom-standard-spinner-color 6s ease-in-out infinite;
  }
}

@keyframes custom-standard-spinner-color {
  100%,
  0% {
    stroke: var(--fidx-color-utility-action, $action-light);
  }
}

@keyframes p-progress-spinner-color-light {
  100%,
  0% {
    stroke: #d62d20;
  }
  40% {
    stroke: #0057e7;
  }
  66% {
    stroke: #008744;
  }
  80%,
  90% {
    stroke: #ffa700;
  }
}

@keyframes p-progress-spinner-color-dark {
  100%,
  0% {
    stroke: #ff3d2e;
  }
  40% {
    stroke: #69a0fb;
  }
  66% {
    stroke: #0bbf66;
  }
  80%,
  90% {
    stroke: #ffa700;
  }
}
