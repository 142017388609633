.p-datatable {
  font-family: $default-font;
  border: $primeng-table-border-size solid var(--fidx-color-grid-border, inherit);
  border-radius: 6px;

  .p-datatable-wrapper,
  .p-datatable-loading-overlay {
    border-radius: 6px;
  }

  &.p-datatable-hoverable-rows {
    .p-datatable-tbody {
      > tr:not(.p-highlight):hover {
        background-color: var(--fidx-color-grid-row-hover-bg, $bg-dark-light);
        color: var(--fidx-color-grid-col-text, $default-light);
      }
    }
  }

  .p-datatable-header {
    background-color: var(--fidx-color-grid-toolbar-bg, $default-light);
    border-top: $primeng-table-header-border-top;
    border-color: var(--fidx-color-grid-border, $grid-border-color-light);
    padding: $primeng-table-header-padding;
    color: var(--fidx-color-text-default, $default-light);
  }

  .search-container {
    padding: $primeng-table-search-container-padding;
    width: $full-width;
  }

  .p-sortable-column {
    &.p-highlight {
      color: var(--fidx-color-grid-row-header-text, $white);
      background: transparent;
      font-weight: $font-weight-bold;

      &:hover {
        color: var(--fidx-color-grid-row-header-text, $white);
      }

      .p-sortable-column-icon {
        color: var(--fidx-color-grid-row-header-text, $white);
      }
    }

    &:not(p.highlight):hover {
      background-color: var(--fidx-color-grid-header-hover-bg, $ix-blue-secondary);
      color: var(--fidx-color-grid-row-header-text, $white);
      .p-sortable-column-icon {
        color: var(--fidx-color-grid-row-header-text, $white);
      }
    }

    .p-sortable-column-icon {
      color: var(--fidx-color-grid-row-header-text, $white);
    }

    .p-column-filter-menu-button {
      color: var(--fidx-color-grid-row-header-text, $white);
      filtericon {
        display: none;
      }
      &:hover {
        background-color: var(--fidx-color-grid-header-bg, $ix-blue-secondary);
      }
      &::after {
        font-family: 'Font Awesome 6 Pro';
        font-weight: 400;
        content: '\f0b0';
      }
      &.p-column-filter-menu-button-open,
      &.p-column-filter-menu-button-active {
        background-color: var(--fidx-color-grid-header-bg, $ix-blue-secondary);
        &:hover {
          background-color: var(--fidx-color-grid-header-bg, $ix-blue-secondary);
        }
        &::after {
          font-family: 'Font Awesome 6 Pro';
          font-weight: 900;
          content: '\f0b0';
        }
      }
    }
  }

  .p-datatable-thead {
    tr {
      background-color: var(--fidx-color-grid-header-bg, $ix-blue-secondary);
      > th {
        background-color: var(--fidx-color-grid-header-bg, $ix-blue-secondary);
        border-bottom-color: var(--fidx-color-grid-col-border, $black-15);
        color: var(--fidx-color-grid-row-header-text, $text-hint-light);
        font-size: $default-font-size;
        border-left: $primeng-table-border-header-size solid var(--fidx-color-grid-header-border, $white);

        &:first-child {
          border-left: $primeng-table-head-first-border-left;
        }
      }
    }
  }

  .p-datatable-tbody {
    > tr {
      background: var(--fidx-color-grid-bg, $bg-default-light);
      color: var(--fidx-color-grid-col-text, $default-light);
      > td {
        border-color: var(--fidx-color-grid-col-border, $black-15);
      }
      &:last-child > td {
        border-bottom-width: $primeng-table-body-row-last-child-border-bottom;
      }
    }
  }

  .p-paginator-bottom {
    border-width: $primeng-table-paginator-border-width;
    border-color: var(--fidx-color-grid-col-border, $black-15);
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
  }

  &.p-datatable-sm {
    .p-datatable-header {
      padding: $primeng-table-small-header-padding;
    }
    .p-datatable-tbody > tr > td {
      padding: $primeng-table-small-body-td-padding;
      font-size: $default-font-size;
      &.empty-data {
        padding-left: $primeng-table-small-body-td-empty-pl;
      }
      &.center {
        text-align: center;
      }
    }
  }

  .p-checkbox-box {
    .fa-check.selected {
      color: var(--fidx-color-form-check-radio-checked-text, $white);
      font-size: $small-font-size;
      font-weight: $font-weight-bold;
    }
  }
}

.p-datatable-resizable {
  &.allow-column-wrap {
    .p-datatable-tbody > tr > td {
      white-space: initial;
    }
  }
}

.p-column-filter-overlay {
  .p-inputtext {
    background-color: var(--fidx-color-form-control-bg, #ffffff);
    border: 1px solid var(--fidx-color-form-control-border, rgba(0, 0, 0, 0.15));
    color: var(--fidx-color-dropdown-text, $default-light);
    font-family: $default-font;
    font-size: $default-font-size;
    line-height: $primeng-dropdown-input-line-height;
  }
  .p-column-filter-add-button,
  .p-column-filter-remove-button {
    font-family: $default-font;
    font-size: $default-font-size;
  }
  .p-column-filter-buttonbar {
    .p-button-sm {
      font-family: $default-font;
      font-size: $btn-text-md;
      border-radius: $btn-border-radius;
      @include primary-button-style(
        'primary',
        $action-light,
        $action-25-light,
        $black-5,
        $action-light,
        $action-light,
        $white,
        $action-50-light
      );
    }
  }
  .p-dropdown {
    .p-inputtext {
      border: none;
    }
  }
}

.p-column-filter-overlay-menu {
  background: var(--fidx-color-bg, $bg-default-dark);
  .p-column-filter-operator {
    background: var(--fidx-color-bg, $bg-default-dark);
  }
}

.positive-value {
  color: var(--fidx-color-grid-positive-value, $positive-light);
}

.negative-value {
  color: var(--fidx-color-grid-negative-value, $negative-light);
}

.empty-data {
  color: var(--fidx-color-grid-emptydata-text, $bg-contrast-default-light);
}
