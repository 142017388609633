.p-toast {
  .p-toast-message {
    font-family: $default-font;
    font-size: $default-font-size;
    .p-toast-message-content {
      justify-content: space-between;
      &.in-progress {
        justify-content: normal;
      }
    }

    &.p-toast-message-success {
      color: var(--fidx-color-toast-success-text, $positive-light);
      background: var(--fidx-color-toast-success-bg, $info-d--3-light);
      border-color: var(--fidx-color-utility-positive, $positive-light);
      .p-toast-icon-close {
        color: var(--fidx-color-toast-success-text, $positive-light);
      }
    }

    &.p-toast-message-warn {
      color: var(--fidx-color-toast-caution-text, $caution-light);
      background: var(--fidx-color-toast-caution-bg, $caution-25-light);
      border-color: var(--fidx-color-utility-caution, $caution-light);
      .p-toast-icon-close {
        color: var(--fidx-color-toast-caution-text, $caution-light);
      }
    }

    &.p-toast-message-error {
      color: var(--fidx-color-toast-negative-text, $negative-light);
      background: var(--fidx-color-toast-negative-bg, $negative-15-light);
      border-color: var(--fidx-color-utility-negative, $negative-light);
      .p-toast-icon-close {
        color: var(--fidx-color-toast-negative-text, $negative-light);
      }
    }

    &.p-toast-message-info {
      color: var(--fidx-color-toast-information-text, $info-light);
      background: var(--fidx-color-toast-information-bg, $info-10-light);
      border-color: var(--fidx-color-utility-information, $info-light);
      .p-toast-icon-close {
        color: var(--fidx-color-toast-information-text, $info-light);
      }
    }

    p-progressspinner {
      .p-progress-spinner-circle {
        animation:
          p-progress-spinner-dash 1.5s ease-in-out infinite,
          var(--fidx-color-toast-spinner-animation, p-progress-spinner-color-light) 6s ease-in-out infinite !important;
      }
    }
  }
}
