.p-tabview {
  .p-tabview-nav {
    border: $primeng-tabs-border;
    background: var(--fidx-color-tabs-bg, $bg-default-light);
    font-family: $default-font;
    padding-left: $primeng-tabs-pl;
    li {
      .p-tabview-nav-link {
        background: var(--fidx-color-tabs-bg, $bg-default-light);
        border: none;
        color: var(--fidx-color-tabs-item-text, $black-55);
        font-size: $default-font-size;
        padding: $primeng-tabs-link-padding;
        &:hover {
          text-decoration: none;
        }
        &:not(.p-disabled):focus {
          box-shadow: none;
        }
      }

      &:not(.p-highlight):not(.p-disabled):hover {
        .p-tabview-nav-link {
          background: var(--fidx-color-tabs-bg, $bg-default-light);
        }
      }

      &.p-highlight {
        .p-tabview-nav-link {
          background: transparent;
          border-bottom: $primeng-tabs-link-highlight-border-bottom;
          border-color: var(--fidx-color-tabs-item-selected-border, $action-light);
          color: var(--fidx-color-tabs-item-selected-text, $default-light);
        }
      }
    }
  }
  .p-tabview-nav-btn {
    &.p-link {
      background: transparent;
    }
  }
  .p-tabview-panels {
    background: var(--fidx-color-tabs-bg, $bg-default-light);
    color: var(--fidx-color-text-default, $default-light);
  }
}
