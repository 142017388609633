%FIDx-form-message-flag {
  display: flex;
  font-size: $small-font-size;
  font-weight: $font-weight-bold;
}

%FIDx-has-error-attributes {
  &:hover {
    border: $form-border-size-alert solid var(--fidx-color-has-error-border, $negative-light);
  }
  &:focus-visible {
    border: $form-border-size-alert solid var(--fidx-color-has-error-border, $negative-light);
  }
  &:focus {
    box-shadow: 0 0 0 $form-box-shadow-sr var(--fidx-color-has-error-border-faded, $negative-25-light);
  }
}

%form-control-styles {
  background-color: var(--fidx-color-form-control-bg, $bg-default-light);
  background-clip: padding-box;
  border: $form-border-size solid var(--fidx-color-form-control-border, $black-15);
  border-radius: $form-border-radius;
  color: var(--fidx-color-form-control-text, $default-light);
  height: $form-control-height;
  padding: $form-control-padding;
  font-size: $default-font-size;
  font-family: $default-font;
  line-height: $form-control-line-height;
  margin-bottom: $spacing-sm;

  &:hover {
    border: $form-border-size solid var(--fidx-color-form-control-hover-border, $action-light);
  }

  &:focus-visible {
    outline: 0;
    border: $form-border-size-alert solid var(--fidx-color-form-control-focus-visible, $action-light);
  }

  &:focus {
    background-color: var(--fidx-color-form-control-focus-bg, $bg-default-light);
    box-shadow: 0 0 0 $form-box-shadow-sr var(--fidx-color-form-control-focus-boxshadow, $action-25-light);
    color: var(--fidx-color-form-control-focus-text, $default-light);
  }

  &:disabled {
    background: var(--fidx-color-form-control-readonly-bg, $bg-dark-light);
    border: $form-border-size solid var(--fidx-color-form-control-disabled-border, $bg-default-light);
    color: var(--fidx-color-form-control-disabled-text, $black-55);
    cursor: no-drop;
    &:hover {
      background-color: var(--fidx-color-form-control-readonly-bg, $bg-dark-light);
      border-color: var(--fidx-color-form-control-disabled-hover-border, transparent);
    }
  }
}
