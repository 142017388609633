.p-breadcrumb {
  background-color: var(--fidx-color-bc-bg, $white);
}

.p-breadcrumb.f-breadcrumb {
  border: $primeng-breadcrumb-border;
  font-family: $default-font;
  ol {
    margin: $primeng-breadcrumb-main-margin;
    padding: $primeng-breadcrumb-main-padding;
    line-height: $primeng-breadcrumb-main-line-height;
    li {
      &.p-disabled {
        opacity: 1;
      }
      .p-menuitem-link,
      .p-menuitem-link-active {
        .p-menuitem-text {
          font-size: $primeng-breadcrumb-font-size;
          color: var(--fidx-color-bc-text, $action-light);
        }
        .p-menuitem-icon {
          color: var(--fidx-color-bc-text, $action-light);
          font-size: $primeng-breadcrumb-icon-size;
        }
      }
      a.p-menuitem-link:hover {
        text-decoration: none;
      }
      &.p-menuitem-separator .p.icon-wrapper {
        width: $primeng-breadcrumb-separator-width;
      }
    }
    li:last-child {
      .p-menuitem-text {
        font-size: $primeng-breadcrumb-font-size;
        color: var(--fidx-color-bc-slink-text, $primeng-breadcrumb-color-last-child);
        font-weight: $font-weight-bold;
      }
    }
    li:not(.p-disabled):hover {
      cursor: pointer;
    }
  }
  .pi-chevron-right {
    line-height: $primeng-breadcrumb-main-right-icon-line-height;
    color: var(--fidx-color-text-default, $default-light);
    &::before {
      font-size: $primeng-breadcrumb-font-size;
    }
  }
}

@media screen and (max-width: 767px) {
  .p-breadcrumb.f-breadcrumb {
    ul {
      li {
        &.p-breadcrumb-chevron {
          margin: $primeng-breadcrumb-mobile-icon-margin;
          font-size: $primeng-breadcrumb-font-size;
        }
        .p-menuitem-link .p-menuitem-text {
          font-size: $primeng-breadcrumb-font-size;
        }
      }
    }
    .pi-chevron-right::before {
      font-size: $primeng-breadcrumb-font-size;
    }
  }
  .subnavbar-sticky {
    .pi {
      font-size: $primeng-breadcrumb-font-size;
    }
  }
}
