$default-dark: #ffffff;
$primary-dark: #69abe6;
$action-dark: #69abe6;
$affirmation-dark: #1bcbc5;
$info-dark: #4099f2;
$caution-dark: #ffe563;
$positive-dark: #1bcbc5;
$negative-dark: #f75959;
$success-dark: $positive-dark;
$warning-dark: $caution-dark; // #ff8d72 !default;
$danger-dark: $negative-dark;
$error-dark: $negative-dark;
$light-dark: #182a3b;
$secondary-dark: #f4f5f7 !default;

$opacity-dark: () !default;
$opacity-dark: map-merge(
  (
    'action': (
      '5': rgba(105, 171, 230, 0.05),
      '10': rgba(105, 171, 230, 0.1),
      '25': rgba(105, 171, 230, 0.25),
      '50': rgba(105, 171, 230, 0.5),
      '55': rgba(105, 171, 230, 0.55)
    ),
    'affirmation': (
      '5': rgba(27, 203, 197, 0.05),
      '10': rgba(27, 203, 197, 0.1),
      '25': rgba(27, 203, 197, 0.25),
      '50': rgba(27, 203, 197, 0.5),
      '55': rgba(27, 203, 197, 0.55)
    ),
    'positive': (
      '5': rgba(27, 203, 197, 0.05),
      '10': rgba(27, 203, 197, 0.1),
      '25': rgba(27, 203, 197, 0.25),
      '50': rgba(27, 203, 197, 0.5),
      '55': rgba(27, 203, 197, 0.55)
    ),
    'negative': (
      '5': rgba(247, 89, 89, 0.05),
      '10': rgba(247, 89, 89, 0.1),
      '25': rgba(247, 89, 89, 0.25),
      '50': rgba(247, 89, 89, 0.5),
      '55': rgba(247, 89, 89, 0.55)
    ),
    'caution': (
      '5': rgba(255, 229, 99, 0.05),
      '10': rgba(255, 229, 99, 0.1),
      '25': rgba(255, 229, 99, 0.25),
      '50': rgba(255, 229, 99, 0.5),
      '55': rgba(255, 229, 99, 0.55)
    )
  ),
  $opacity-dark
);

$action-5-dark: rgba(105, 171, 230, 0.05);
$action-10-dark: rgba(105, 171, 230, 0.1);
$action-25-dark: rgba(105, 171, 230, 0.25);
$action-50-dark: rgba(105, 171, 230, 0.5);
$action-55-dark: rgba(105, 171, 230, 0.55);
$negative-5-dark: rgba(247, 89, 89, 0.05);
$negative-15-dark: rgba(247, 89, 89, 0.15);
$negative-25-dark: rgba(247, 89, 89, 0.25);
$caution-5-dark: rgba(255, 229, 99, 0.05);
$caution-25-dark: rgba(255, 229, 99, 0.25);
$positive-5-dark: rgba(27, 203, 197, 0.05);
$positive-15-dark: rgba(27, 203, 197, 0.15);
$positive-25-dark: rgba(27, 203, 197, 0.25);
$info-10-dark: rgba(64, 153, 242, 0.1);

$utility-colors-dark: () !default;
$utility-colors-dark: map-merge(
  (
    'action': $action-dark,
    'affirmation': $affirmation-dark,
    'information': $info-dark,
    'caution': $caution-dark,
    'positive': $positive-dark,
    'negative': $negative-dark,
    'error': $error-dark,
    'light': $light-dark
  ),
  $utility-colors-dark
);

$text-hint-dark: #d4dfea;
$text-disabled-dark: #b6c5d4;

$bg-default-dark: #001524;
$bg-light-dark: #0a1e2d;
$bg-dark-dark: #122533;
$bg-shade-dark: $black-55;
$bg-contrast-default-dark: #343741;

$mu-default-dark: #001524;
$mu-shade-dark: #122533;
$mu-solid-dark: #004671;

$web-divider-dark: #324a60;
$input-divider-dark: #516578;

$btn-primary-text-dark: $bg-default-dark;
$btn-secondary-text-dark: $action-dark;

// $info-a--3: #EDF6F7;
// $info-a--2: #CCE6E9;
// $info-a--1: #98D3DA;
$info-a-dark: #50c7d3;
// $info-a-1: #00737F;
$info-a-2-dark: #abf6fe;
$info-a-3-dark: #0b6b76;

// $info-b--3: #FFECF0;
// $info-b--2: #FFB0C2;
// $info-b--1: #FF6185;
$info-b-dark: #ff7961;
// $info-b-1: #C9294E;
$info-b-2-dark: #ffc6ba;
$info-b-3-dark: #94322a;

// $info-c--3: #FAF3FD;
// $info-c--2: #EACAF7;
// $info-c--1: #CB93E2;
$info-c-dark: #d05ce3;
// $info-c-1: #9833C2;
$info-c-2-dark: #ebbcf2;
$info-c-3-dark: #791488;

// $info-d--3: #F4F9F1;
// $info-d--2: #CEE4C4;
// $info-d--1: #75A95D;
$info-d-dark: #80e27e;
// $info-d-1: #3E6F27;
$info-d-2-dark: #cbf2c9;
$info-d-3-dark: #e8ffe6;

// $info-e--3: #FEF5EE;
// $info-e--2: #FCD3B7;
// $info-e--1: #FD8940;
$info-e-dark: #f8bc58;
// $info-e-1: #B64F0A;
$info-e-2-dark: #fbe2b6;
$info-e-3-dark: #bf7f1d;

// $info-f--3: #F3F4FE;
// $info-f--2: #CBD2F9;
// $info-f--1: #9EABF3;
$info-f-dark: #8798ef;
// $info-f-1: #485DD2;
$info-f-2-dark: #cbd2f9;
$info-f-3-dark: #2d3a84;

// $info-g--3: #F3F9FE;
// $info-g--2: #CBE0F9;
// $info-g--1: #9EC0F3;
$info-g-dark: #6ec6ff;
// $info-g-1: #0168AD;
$info-g-2-dark: #ddf1fe;
$info-g-3-dark: #0d7abb;

// $info-h--3: #F1F1F1;
// $info-h--2: #DDDDDD;
// $info-h--1: #C0C0C0;
$info-h-dark: #a1a1a1;
// $info-h-1: #676767;
$info-h-2-dark: #dddddd;
$info-h-3-dark: #414141;

$link-color-dark: #69abe6;
$link-active-dark: #0066ff;
// $link-decoration: none !default;
// $link-hover-decoration: none !default;

$grid-border-color-dark: $web-divider-dark;
$grid-pager-bg-dark: $bg-default-dark;

$loader-overlay-bg-dark: rgba(71, 71, 71, 0.6);
$loader-scroll-bg-dark: #4f5152;

$risk-tolerance-bg-dark: #8fb5bf;

$workbench-action-light-dark: #03101c;
$workbench-action-dark-dark: #063863;
$workbench-action-hover-light-dark: #03446d;
$workbench-action-hover-dark-dark: #011f31;
$workbench-action-border-dark: #2b4155;
$workbench-transact-icon-dark: #f59638;

$tiered-menu-active-bg-dark: #0f2a4c;

$compare-allocation-bg-dark: $bg-contrast-default-dark;
$compare-allocation-meter-boxshadow-dark: #484848;

$filters-tab-bg-dark: #4f5152;
