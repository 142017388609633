.p-fileupload {
  .p-fileupload-buttonbar {
    display: none;
  }
  .p-fileupload-content {
    background: var(--fidx-color-card-bg, $white);
    padding: $primeng-upload-padding;
    border: $form-border-size dashed var(--fidx-color-form-control-border, $black-15);
    color: var(--fidx-color-text-default, $default-light);
    border-radius: $form-border-radius;
  }
}
