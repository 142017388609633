.p-paginator {
  background: var(--fidx-color-grid-bg, $bg-default-light);
  color: var(--fidx-color-grid-col-text, $default-light);
  padding: $primeng-pagination-padding;
  font-family: $default-font;
  .p-paginator-first,
  .p-paginator-prev,
  .p-paginator-next,
  .p-paginator-last {
    min-width: inherit;
    height: inherit;
    padding: $primeng-pagination-links-padding;
    border-radius: 50%;
    color: var(--fidx-color-grid-col-text, $default-light);
  }

  .p-paginator-current {
    color: var(--fidx-color-grid-col-text, $default-light);
    min-width: inherit;
    height: $primeng-pagination-current-height;
    font-size: $default-font-size;
  }

  .p-paginator-pages {
    .p-paginator-page {
      min-width: $primeng-pagination-page-width;
      height: $primeng-pagination-page-height;
      padding: $primeng-pagination-page-padding;
      border-radius: 50%;
      font-size: $default-font-size;
      line-height: 14px;
      &.p-highlight {
        min-width: $primeng-pagination-page-width;
        min-height: $primeng-pagination-page-height;
      }
    }
  }

  .p-dropdown {
    margin-left: inherit;
    margin-right: inherit;
    height: inherit;
    margin: $primeng-pagination-dropdown-margin;
    width: auto;
    > .p-inputtext {
      padding: $primeng-pagination-dropdown-padding;
      line-height: 16px;
    }
    .p-dropdown-trigger {
      width: auto !important;
      padding: $primeng-pagination-dropdown-padding;
    }
  }
}
