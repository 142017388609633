.p-confirm-popup {
  font-family: $default-font;
  font-size: $default-font-size;
  background-color: var(--fidx-color-dialog-content-bg, $bg-default-light);
  box-shadow: $primeng-confirm-box-shadow var(--fidx-color-dialog-content-boxshadow, $black-8);
  color: var(--fidx-color-dialog-content-text, $default-light);

  button.p-confirm-popup-accept {
    @extend .btn-primary;
  }

  button.p-confirm-popup-reject {
    @extend .btn-secondary;
    margin-right: $primeng-confirm-reject-mr;
    margin-bottom: $primeng-confirm-reject-mb;
  }
}
