@import './variables';

// VARIABLES TO USE
$utilities: 'action', 'affirmation', 'information', 'caution', 'positive', 'negative', 'error', 'light';
$btn-map-section: (
  'affirm': 'affirmation',
  'success': 'positive',
  'error': 'negative',
  'caution': 'caution'
);
$alert-map-section: (
  'error': 'negative',
  'warning': 'caution',
  'info': 'information',
  'success': 'positive'
);
$chip-sections: 'positive', 'affirmation', 'caution', 'negative', 'information';

[data-theme='light'] {
  --fidx-color-bg: #{$bg-default-light};
  --fidx-color-primary-black: #{$black};
  --fidx-color-primary-white: #{$white};
  --fidx-color-shadow-num: #{$black-num};
  --fidx-color-breadcrumb: #{$breadcrumb-light};

  --fidx-color-text-default: #{$default-light};
  --fidx-color-text-hint: #{$text-hint-light};
  --fidx-color-text-disabled: #{$text-disabled-light};
  --fidx-color-text-link: #{$link-color-light};

  --fidx-color-btn-secondary-text: #{$btn-secondary-text-light};

  @each $utility in $utilities {
    --fidx-color-utility-#{$utility}: #{map-get($utility-colors-light, $utility)};
  }

  --fidx-color-has-error-border: #{$negative-light};
  --fidx-color-has-error-border-faded: #{$negative-25-light};
  --fidx-color-has-warning-border: #{$negative-light};
  --fidx-color-has-warning-focus-faded: #{$negative-25-light};

  --fidx-color-scrollbar-thumb-bg: #{$black-25};
  --fidx-color-scrollbar-thumb-border: #{$bg-default-light};
  --fidx-color-scrollbar-thumb-active-bg: #{$action-light};
  --fidx-color-scrollbar-track-bg: #{$white};
  --fidx-color-scrollbar-track-border: #{$bg-default-light};
  --fidx-color-scrollbar-firefox: #{$black-25} #{$white};

  --fidx-color-required: #{$negative-light};
  --fidx-color-error: #{$negative-light};
  --fidx-color-warning: #{$caution-light};

  --fidx-color-loading-icon-border: #{$white};
  --fidx-color-loading-icon-border-top: #{$action-light};

  --fidx-color-nav-link: #{$black-55};
  --fidx-color-nav-link-hover: #{$black-55};

  --fidx-color-container-bg: #{$bg-dark-light};

  // BTN VARIABLES
  --fidx-color-btn-primary-bg: #{$action-light};
  --fidx-color-btn-primary-text: #{$white};
  --fidx-color-btn-primary-weight: #{$font-weight-normal};
  --fidx-color-btn-primary-hover-bg-grad: #{$black-5};
  --fidx-color-btn-primary-hover-bg-layer: #{$action-light};
  --fidx-color-btn-primary-hover-border: #{$action-light};
  --fidx-color-btn-primary-active-bg-grad: #{$black-10};
  --fidx-color-btn-primary-active-bg-layer: #{$action-light};
  --fidx-color-btn-primary-active-border: #{$action-light};
  --fidx-color-btn-primary-active-hover-border: #{$action-light};
  --fidx-color-btn-primary-focus-boxshadow: #{$action-25-light};
  --fidx-color-btn-primary-disabled-bg: #{$action-50-light};
  --fidx-color-btn-primary-disabled-border: #{$action-25-light};

  @each $class, $section in $btn-map-section {
    --fidx-color-btn-primary-#{$class}-bg: #{map-get($utility-colors-light, $section)};
    --fidx-color-btn-primary-#{$class}-hover-bg-grad: #{$black-5};
    --fidx-color-btn-primary-#{$class}-border: #{map-get($utility-colors-light, $section)};
    --fidx-color-btn-primary-#{$class}-hover-bg-layer: #{map-get($utility-colors-light, $section)};
    --fidx-color-btn-primary-#{$class}-hover-border: #{map-get($utility-colors-light, $section)};
    --fidx-color-btn-primary-#{$class}-active-bg-grad: #{$black-10};
    --fidx-color-btn-primary-#{$class}-active-bg-layer: #{map-get($utility-colors-light, $section)};
    --fidx-color-btn-primary-#{$class}-active-border: #{map-get($utility-colors-light, $section)};
    --fidx-color-btn-primary-#{$class}-active-hover-border: #{map-get($utility-colors-light, $section)};
    --fidx-color-btn-primary-#{$class}-text: #{$white};
    --fidx-color-btn-primary-#{$class}-weight: 400;
    --fidx-color-btn-primary-#{$class}-boxshadow: #{map-get(map-get($opacity-light, $section), '25')};
    --fidx-color-btn-primary-#{$class}-disabled: #{map-get(map-get($opacity-light, $section), '50')};
    --fidx-color-btn-primary-#{$class}-disabled-bg: #{map-get(map-get($opacity-light, $section), '50')};
    --fidx-color-btn-primary-#{$class}-disabled-border: #{map-get(map-get($opacity-light, $section), '25')};
  }

  --fidx-color-btn-secondary-bg: #{$bg-default-light};
  --fidx-color-btn-secondary-border: #{$black-15};
  --fidx-color-btn-secondary-text: #{$action-light};
  --fidx-color-btn-secondary-weight: #{$font-weight-normal};
  --fidx-color-btn-secondary-hover-border: #{$black-15};
  --fidx-color-btn-secondary-hover-bg-grad: #{$action-5-light};
  --fidx-color-btn-secondary-hover-bg-layer: #{$bg-default-light};
  --fidx-color-btn-secondary-active-border: #{$action-light};
  --fidx-color-btn-secondary-active-bg-grad: #{$action-10-light};
  --fidx-color-btn-secondary-active-bg-layer: #{$bg-default-light};
  --fidx-color-btn-secondary-focus-boxshadow: #{$action-25-light};
  --fidx-color-btn-secondary-disabled-bg: #{$bg-default-light};
  --fidx-color-btn-secondary-disabled-border: #{$black-15};

  @each $class, $section in $btn-map-section {
    --fidx-color-btn-secondary-#{$class}-bg: #{$bg-default-light};
    --fidx-color-btn-secondary-#{$class}-border: #{map-get($utility-colors-light, $section)};
    --fidx-color-btn-secondary-#{$class}-text: #{map-get($utility-colors-light, $section)};
    --fidx-color-btn-secondary-#{$class}-weight: #{$font-weight-normal};
    --fidx-color-btn-secondary-#{$class}-hover-border: #{map-get(map-get($opacity-light, $section), '25')};
    --fidx-color-btn-secondary-#{$class}-hover-bg-grad: #{map-get(map-get($opacity-light, $section), '5')};
    --fidx-color-btn-secondary-#{$class}-hover-bg-layer: #{$bg-default-light};
    --fidx-color-btn-secondary-#{$class}-active-border: #{map-get($utility-colors-light, $section)};
    --fidx-color-btn-secondary-#{$class}-active-bg-grad: #{map-get(map-get($opacity-light, $section), '10')};
    --fidx-color-btn-secondary-#{$class}-active-bg-layer: #{$bg-default-light};
    --fidx-color-btn-secondary-#{$class}-focus-boxshadow: #{map-get(map-get($opacity-light, $section), '25')};
    --fidx-color-btn-secondary-#{$class}-disabled-bg: #{$bg-default-light};
    --fidx-color-btn-secondary-#{$class}-disabled-border: #{map-get($utility-colors-light, $section)};
  }

  --fidx-color-btn-textbtn-bg: transparent;
  --fidx-color-btn-textbtn-border: transparent;
  --fidx-color-btn-textbtn-text: #{$default-light};
  --fidx-color-btn-textbtn-weight: #{$font-weight-bold};
  --fidx-color-btn-textbtn-hover-bg: #{$black-2};
  --fidx-color-btn-textbtn-active-bg: #{$black-4};
  --fidx-color-btn-textbtn-focus-bg: #{$action-25-light};
  --fidx-color-btn-textbtn-focus-boxshadow: #{$action-25-light};
  --fidx-color-btn-textbtn-focus-hover-bg: #{$action-25-light};
  --fidx-color-btn-textbtn-disabled-hover-bg: #{$bg-default-light};

  // FORM VARIABLES
  --fidx-color-form-input-container: #{$bg-default-light};
  --fidx-color-form-placeholder: #{$black-55};
  --fidx-color-form-control-text: #{$default-light};
  --fidx-color-form-control-bg: #{$bg-default-light};
  --fidx-color-form-control-border: #{$black-15};
  --fidx-color-form-control-hover-border: #{$action-light};
  --fidx-color-form-control-focus-bg: #{$bg-default-light};
  --fidx-color-form-control-focus-text: #{$default-light};
  --fidx-color-form-control-focus-visible: #{$action-light};
  --fidx-color-form-control-focus-boxshadow: #{$action-25-light};
  --fidx-color-form-control-readonly-bg: #{$bg-dark-light};
  --fidx-color-form-control-readonly-border: #{$black-55};
  --fidx-color-form-control-disabled-bg: #{$bg-dark-light};
  --fidx-color-form-control-disabled-border: #{$bg-default-light};
  --fidx-color-form-control-disabled-hover-border: transparent;
  --fidx-color-form-control-disabled-text: #{$black-55};
  --fidx-color-form-control-selection: #{$action-55-light};
  --fidx-color-form-input-hidden-bg: #{$white};
  --fidx-color-form-control-outline-bg: #{$bg-default-light};
  --fidx-color-form-control-outline-border: #{$black-15};

  --fidx-color-form-check-radio-bg: #{$bg-default-light};
  --fidx-color-form-check-radio-border: #{$black-15};
  --fidx-color-form-check-radio-border-focus: #{$black-15};
  --fidx-color-form-check-radio-focus-boxshadow: #{$action-25-light};
  --fidx-color-form-check-radio-checked-text: #{$white};
  --fidx-color-form-check-radio-checked-border: #{$action-light};
  --fidx-color-form-check-radio-checked-bg: #{$action-light};
  --fidx-color-form-check-radio-checked-focus-border: #{$action-light};
  --fidx-color-form-check-radio-checked-focus-boxshadow: #{$action-25-light};
  --fidx-color-form-check-radio-disabled-text: #{$black-25};
  --fidx-color-form-check-radio-disabled-bg: #{$bg-dark-light};
  --fidx-color-form-check-radio-disabled-border: #{$white};
  --fidx-color-form-error-bg: #{$white};
  --fidx-color-form-warning-bg: #{$white};

  --fidx-color-datepicker-content-text: #{$action-light};
  --fidx-color-datepicker-selected-bg: #{$action-light};
  --fidx-color-datepicker-selected-text: #{$white};
  --fidx-color-datepicker-bg-hover: #{$action-25-light};
  --fidx-color-datepicker-text-hover: #{$text-hint-light};
  --fidx-color-form-control-datepicker-border: #{$black-15};
  --fidx-color-form-control-datepicker-selection-bg: #{$action-55-light};
  --fidx-color-form-control-datepicker-focus-border: #{$action-light};
  --fidx-color-form-control-datepicker-focus-boxshadow: #{$action-25-light};
  --fidx-color-form-control-datepicker-button-bg: #{$bg-default-light};
  --fidx-color-form-control-datepicker-button-color: #{$action-light};
  --fidx-color-form-control-datepicker-today-color: #{$action-light};
  --fidx-color-form-control-datepicker-today-hover-color: #{$action-light};
  --fidx-color-form-control-datepicker-action-border: #{$action-light};
  --fidx-color-form-control-datepicker-selected-bg: #{$bg-default-light};
  --fidx-color-form-control-datepicker-calendar-bg: #{$bg-default-light};
  --fidx-color-form-control-datepicker-calendar-text: #{$default-light};

  --fidx-color-form-button-component-footer-bg: #{$white};
  --fidx-color-form-button-component-footer-boxshadow: #{$black-10};
  --fidx-color-form-control-selector-group-error-text: #{$negative-light};
  --fidx-color-form-control-selector-group-error-bg: #{$white};
  --fidx-color-form-control-selector-group-disabled-text: #{$black-25};

  // CHIP VARIABLES
  --fidx-color-chip-text: #{$default-light};
  --fidx-color-chip-bg: #{$bg-dark-light};
  --fidx-color-chip-close-text: #{$black-55};
  --fidx-color-chip-close-border: #{$black-15};
  --fidx-color-chip-default-bg: #{$bg-dark-light};
  // --fidx-color-chip-default-select-bg: #232020;
  --fidx-color-chip-default-select-text: #{$white};

  @each $section in $chip-sections {
    --fidx-color-chip-#{$section}-bg: #{map-get($utility-colors-light, $section)};
    --fidx-color-chip-#{$section}-border: #{map-get($utility-colors-light, $section)};
    --fidx-color-chip-#{$section}-text: #{$white};
    --fidx-color-chip-#{$section}-icon-text: #{$white};
    --fidx-color-chip-#{$section}-icon-close-border: #{$white-55};
  }

  // DROPDOWN VARIABLES
  --fidx-color-dropdown-text: #{$default-light};
  --fidx-color-dropdown-bg: #{$bg-default-light};
  --fidx-color-dropdown-border: #{$action-light};
  --fidx-color-dropdown-boxshadow: #{$action-25-light};
  --fidx-color-dropdown-input-selection-bg: #{$action-55-light};
  --fidx-color-dropdown-animation-boxshadow: #{$black-8};
  --fidx-color-dropdown-list-container-bg: #{$bg-default-light};
  --fidx-color-dropdown-list-item-hover-bg: #{$light-light};
  --fidx-color-dropdown-list-item-selected-bg: #{$bg-default-light};
  --fidx-color-dropdown-list-item-selected-text: #{$default-light};
  --fidx-color-dropdown-button-text: #{$default-light};
  --fidx-color-dropdown-button-bg: #{$bg-default-light};
  --fidx-color-dropdown-button-select-border: #{$black-15};
  --fidx-color-dropdown-trigger: #{$black-55};

  --fidx-color-dropdown-multi-list-item-selected-bg: #{$action-light};
  --fidx-color-dropdown-multi-list-item-selected-text: #{$white};

  --fidx-color-combobox-select-bg: #{$bg-default-light};

  // TOGGLE VARIABLES
  --fidx-color-toggle-before-bg: #{$white};
  --fidx-color-toggle-before-border: #{$black-15};
  --fidx-color-toggle-knob-bg: #{$white};
  --fidx-color-toggle-knob-text: #{$black-55};
  --fidx-color-toggle-knob-boxshadow: #{$black-25};
  --fidx-color-toggle-checked-bg: #{$action-light};
  --fidx-color-toggle-knob-checked-bg: #{$white};
  --fidx-color-toggle-knob-checked-text: #{$action-light};
  --fidx-color-toggle-disabled-before-bg: #{$bg-dark-light};
  --fidx-color-toggle-disabled-before-border: #{$bg-dark-light};
  --fidx-color-toggle-disabled-knob-text: #{$black-55};

  // CARD VARIABLES
  --fidx-color-card-bg: #{$white};
  --fidx-color-card-border: #{$black-15};
  --fidx-color-card-panel-gradient: linear-gradient(180deg, #{$white} 42.34%, #{$card-panel-dark-gradient-light} 100%);
  --fidx-color-card-panel-read-more-gradient: #{$card-panel-read-more-gradient-light};

  // ALERT VARIABLES
  --fidx-color-alert-bg: #{$mu-default-light};
  --fidx-color-alert-text: #{$black-55};
  --fidx-color-alert-bg-shade: #{$mu-shade-light};
  --fidx-color-alert-bg-solid: #{$mu-solid-light};
  --fidx-color-alert-header-text: #{$default-light};

  @each $section, $class in $alert-map-section {
    --fidx-color-alert-#{$section}-icon-text: #{map-get($utility-colors-light, $class)};
  }

  --fidx-color-tooltip-bg: #{$mu-solid-light};
  --fidx-color-tooltip-callout: #{$mu-solid-light};

  // TAB VARIABLES
  --fidx-color-tabs-bg: #{$bg-default-light};
  --fidx-color-tabs-item-text: #{$black-55};
  --fidx-color-tabs-nested-text: #{$black-55};
  --fidx-color-tabs-item-selected-text: #{$default-light};
  --fidx-color-tabs-item-selected-border: #{$action-light};

  // GRID VARIABLES
  --fidx-color-grid-bg: #{$bg-default-light};
  --fidx-color-grid-border: #{$grid-border-color-light};
  --fidx-color-grid-toolbar-bg: #{$bg-default-light};
  --fidx-color-grid-header-border: #{$web-divider-light};
  --fidx-color-grid-header-icon-text: #{$white};
  --fidx-color-grid-header-icon-hl-text: #{$black};
  --fidx-color-grid-header-bg: #{$action-light};
  --fidx-color-grid-header-hover-bg: #{$action-light};
  --fidx-color-grid-row-alt-bg: #{$white};
  --fidx-color-grid-row-header-bg: #{$action-light};
  --fidx-color-grid-row-header-text: #{$white};
  --fidx-color-grid-col-text: #{$default-light};
  --fidx-color-grid-col-border: #{$black-15};
  --fidx-color-grid-row-hover-bg: #{$bg-dark-light};
  --fidx-color-grid-sort-icon-text: #{$text-hint-light};
  --fidx-color-grid-negative-value: #{$negative-light};
  --fidx-color-grid-positive-value: #{$positive-light};
  --fidx-color-grid-emptydata-text: #{$bg-contrast-default-light};
  --fidx-color-grid-pager-text: #{$default-light};
  --fidx-color-grid-pager-bg: #{$grid-pager-bg-light};
  --fidx-color-grid-pager-border: #{$grid-border-color-light};
  --fidx-color-grid-paging-text: #{$action-light};
  --fidx-color-grid-paging-bg: #{$bg-default-light};
  --fidx-color-grid-paging-selected-text: #{$action-light};
  --fidx-color-grid-paging-selected-bg: #{$white};
  --fidx-color-grid-paging-selected-border: #{$black-15};
  --fidx-color-grid-paging-icon-text: #{$action-light};
  --fidx-color-grid-paging-hover-bg: #{$light-light};
  --fidx-color-grid-menu-item-selected-bg: #{$light-light};
  --fidx-color-grid-menu-item-selected-text: #{$action-light};
  --fidx-color-grid-checkbox-selected-border: #{$action-light};
  --fidx-color-grid-checkbox-selected-bg: #{$action-light};
  --fidx-color-grid-checkbox-selected-text: #{$white};
  --fidx-color-grid-button-border: #{$black-15};
  --fidx-color-grid-button-bg: #{$white};
  --fidx-color-grid-button-text: #{$action-light};
  --fidx-color-grid-button-primary-border: #{$action-light};
  --fidx-color-grid-button-primary-bg: #{$action-light};
  --fidx-color-grid-button-primary-text: #{$white};
  --fidx-color-grid-button-primary-hover-border: #{$action-light};
  --fidx-color-grid-button-primary-hover-bg: #{$action-light};

  // LOADER VARIABLES
  --fidx-color-loader-border: #{$black-15};
  --fidx-color-loader-border-top: #{$action-light};
  --fidx-color-loader-overlay-bg: #{$loader-overlay-bg-light};
  --fidx-color-loader-overlay-container-bg: #{$white};
  --fidx-color-loader-scroll-bg: #{$loader-scroll-bg-light};

  // MODEL VARIABLES
  --fidx-color-dialog-content-bg: #{$bg-default-light};
  --fidx-color-dialog-content-text: #{$default-light};
  --fidx-color-dialog-content-boxshadow: #{$black-8};
  --fidx-color-dialog-icon-triangle-text: #{$caution-light};

  // TOAST VARIABLES
  --fidx-color-toast-success-bg: #{$info-d--3-light};
  --fidx-color-toast-caution-bg: #{$toast-caution-bg-light};
  --fidx-color-toast-caution-text: #{$toast-caution-text-light};
  --fidx-color-toast-negative-bg: #{$toast-negative-bg-light};
  --fidx-color-toast-information-bg: #{$toast-information-bg-light};
  --fidx-color-toast-spinner-animation: p-progress-spinner-color-light;

  // SITE VARIABLES
  --fidx-color-body-bg: #{$mu-shade-light};

  // HEADER VARIABLES
  --fidx-color-header-bg: #{$white};
  --fidx-color-header-nav-active-link: #{$link-active-light};
  --fidx-color-header-user-text: #{$black-55};
  --fidx-color-dropdown-advisortools-text: #{$action-light};

  // BREADCRUMB VARIABLES
  --fidx-color-bc-bg: #{$white};
  --fidx-color-bc-sticky-border: #{$black-15};
  --fidx-color-bc-sticky-boxshadow: #{$black-15};
  --fidx-color-bc-header: #{$default-light};
  --fidx-color-bc-text: #{$action-light};
  --fidx-color-bc-alink-text: #{$action-light};
  --fidx-color-bc-slink-text: #{$link-breadcrumb-slink-light};
  --fidx-color-bc-close-text: #{$black-55};

  // FOOTER VARIABLES
  --fidx-color-footer-bg: #{$bg-light-light};
  --fidx-color-footer-text: #{$black-55};
  --fidx-color-footer-link-text: #{$black-55};
  --fidx-color-footer-divider-text: #{$black-15};

  // SPECIFIC PAGE VARIABLES
  --fidx-color-home-bg: #{$home-bg-light};
  --fidx-color-home-bg-gradient: linear-gradient(transparent, #{$home-bg-light});
  --fidx-color-home-bg-non-gradient: transparent;

  --fidx-color-pd-selection-bg: #{$light-light};
  --fidx-color-pd-selection-header-text: #{$black-55};
  --fidx-color-pd-target-bg: #{$bg-contrast-default-light};
  --fidx-color-pd-target-text: #{$light-light};

  --fidx-color-wb-bg-light: #{$workbench-action-light-light};
  --fidx-color-wb-bg-dark: #{$workbench-action-dark-light};
  --fidx-color-wb-bg-hover-light: #{$workbench-action-hover-light-light};
  --fidx-color-wb-bg-hover-dark: #{$workbench-action-hover-dark-light};
  --fidx-color-wb-border: #{$workbench-action-border-light};
  --fidx-color-wb-transact: #{$workbench-transact-icon-light};

  --fidx-color-risk-border: #{$default-light};
  --fidx-color-risk-tolerance-bg: #{$risk-tolerance-bg-light};
  --fidx-color-risk-pointer: #{$negative-light};
  --fidx-color-risk-model: #{$affirmation-light};
  --fidx-color-risk-portfolio-bg: #{map-get(map-get($opacity-light, 'negative'), '10')};

  --fidx-color-tieredmenu-active-bg: #{$tiered-menu-active-bg-light};

  --fidx-color-compare-grid-header-bg: #{$bg-dark-light};
  --fidx-color-compare-allocation-bg: #{$compare-allocation-bg-light};
  --fidx-color-compare-allocation-meter-border: #{$white};
  --fidx-color-compare-allocation-meter-boxshadow: #{$compare-allocation-meter-boxshadow-light};
  --fidx-color-asset-class-color-border: #{$black-15};
  --fidx-color-compare-spacer-bg: #{map-get(map-get($opacity-light, 'affirmation'), '25')};
  --fidx-color-compare-grid-row-hover-bg: #e6eaef;

  --fidx-color-filters-tab-bg: #{$filters-tab-bg-light};

  --fidx-color-category-1-bg: #{$category-1-bg-light};
  --fidx-color-category-2-bg: #{$category-2-bg-light};
  --fidx-color-category-3-bg: #{$category-3-bg-light};
  --fidx-color-category-1-text: #{$bg-default-light};
  --fidx-color-category-2-text: #{$bg-default-light};
  --fidx-color-category-3-text: #{$default-light};

  --fidx-color-carousel-btn-bg: #{$carousel-button-disabled-light};

  --fidx-color-dashboard-grid-header-bg: #{$ix-blue-secondary};
  --fidx-color-dashboard-grid-row-header-text: #{$white};

  // CHART COLORS
  --fidx-color-chart-bg-1: #{$info-a-light};
  --fidx-color-chart-bg-2: #{$info-b-light};
  --fidx-color-chart-bg-3: #{$info-c-light};
  --fidx-color-chart-bg-4: #{$info-d-light};
  --fidx-color-chart-bg-5: #{$info-e-light};
  --fidx-color-chart-bg-6: #{$info-f-light};
  --fidx-color-chart-bg-7: #{$info-g-light};
  --fidx-color-chart-bg-8: #{$info-h-light};
  --fidx-color-chart-bg-9: #{$info-a-2-light};
  --fidx-color-chart-bg-10: #{$info-b-2-light};
  --fidx-color-chart-bg-11: #{$info-c-2-light};
  --fidx-color-chart-bg-12: #{$info-d-2-light};
  --fidx-color-chart-bg-13: #{$info-e-2-light};
  --fidx-color-chart-bg-14: #{$info-f-2-light};
  --fidx-color-chart-bg-15: #{$info-g-2-light};
  --fidx-color-chart-bg-16: #{$info-h-2-light};
}

::before {
  --fidx-color-toggle-before-bg: #{$white};
  --fidx-color-toggle-before-border: #{$black-15};
}
