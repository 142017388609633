.p-checkbox {
  width: $primeng-checkbox-width;
  height: $primeng-checkbox-height;

  &:not(.p-checkbox-disabled) {
    .p-checkbox-box.p-highlight {
      &:hover {
        background: linear-gradient(
            0deg,
            var(--fidx-color-btn-primary-hover-bg-grad, $black-5),
            var(--fidx-color-btn-primary-hover-bg-grad, $black-5)
          ),
          var(--fidx-color-btn-primary-hover-bg-layer, $action-light);
        border-color: var(--fidx-color-btn-primary-hover-border, $action-light);
      }
    }
  }
  .p-checkbox-box {
    width: $primeng-checkbox-width;
    height: $primeng-checkbox-height;
    font-size: $primeng-checkbox-font-size;
    line-height: $primeng-checkbox-line-height;
    background: var(--fidx-color-form-check-radio-bg, $bg-default-light);
    border-color: var(--fidx-color-form-check-radio-border, $black-15);

    &:not(.p-disabled):not(.p-highlight) {
      &:hover {
        border-color: var(--fidx-color-utility-action, $action-light);
      }
    }

    &.p-highlight {
      border-color: var(--fidx-color-form-check-radio-checked-border, $action-light);
      background: var(--fidx-color-form-check-radio-checked-bg, $action-light);
    }

    .p-checkbox-icon {
      color: var(--fidx-color-form-check-radio-checked-text, $white);
      font-size: $primeng-checkbox-font-size;
      font-weight: $font-weight-bold;
    }
  }
}
