.p-datepicker {
  background: var(--fidx-color-form-input-container, $bg-default-light);
  color: var(--fidx-color-text-hint, $text-hint-light);

  .p-datepicker-header {
    padding: inherit;
  }

  table {
    width: 100%;
    font-family: $default-font;
    td {
      padding: inherit;
      > span {
        width: 36px;
        height: 36px;
        &.p-highlight {
          color: var(--fidx-color-datepicker-selected-text, $white);
          background: var(--fidx-color-datepicker-selected-bg, $action-light);
          font-weight: $font-weight-strong;
        }
      }
    }
  }
}

.p-datepicker:not(.p-disabled) table td span:not(.p-highlight):not(.p-disabled):hover {
  color: var(--fidx-color-datepicker-text-hover, $text-hint-light);
  background: var(--fidx-color-datepicker-bg-hover, $action-25-light);
}

.p-datepicker:not(.p-datepicker-inline) {
  background: var(--fidx-color-form-input-container, $bg-default-light);
  .p-datepicker-header {
    font-family: $default-font;
    background: var(--fidx-color-form-input-container, $bg-default-light);
    .p-datepicker-title {
      .p-datepicker-month,
      .p-datepicker-year {
        color: var(--fidx-color-text-hint, $text-hint-light);
      }
    }
  }
}

.p-calendar {
  &.p-input-icon-right > i {
    position: absolute;
    top: 47%;
    right: 12px;
    color: var(--fidx-color-utility-action, $action-light);
    margin-top: -10px;
  }
}

.p-element.ng-touched.ng-invalid,
app-utc-calendar.ng-touched.ng-invalid {
  .p-calendar {
    .p-inputtext {
      @extend .has-error !optional;
      // border-right: none;
      @extend %FIDx-has-error-attributes !optional;
    }
    .p-inputtext.has-error {
      + button.p-button {
        border: $form-border-size-alert solid var(--fidx-color-error, $negative-light);
        border-left: none;
      }
      &:enabled:focus {
        box-shadow:
          $primeng-calendar-box-shadow-1 var(--fidx-color-form-control-bg, $bg-default-light),
          $primeng-calendar-box-shadow-2 var(--fidx-color-has-error-border-faded, $negative-25-light),
          $primeng-calendar-box-shadow-3 var(--fidx-color-has-error-border-faded, $negative-25-light);
      }
    }
  }
}
