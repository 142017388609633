.has-error {
  border: $form-border-size-alert solid var(--fidx-color-error, $negative-light);
  background-color: var(--fidx-color-form-error-bg, $white);
}

.error-text {
  color: var(--fidx-color-error, $negative-light);
}

.has-warning {
  border: $form-border-size-alert solid var(--fidx-color-warning, $caution-light);
  background-color: var(--fidx-color-form-warning-bg, $white);
}

.warning-text {
  color: var(--fidx-color-warning, $caution-light);
}

.error-flag,
.warning-flag {
  @extend %FIDx-form-message-flag;
}
