$default-light: rgba(0, 0, 0, 0.85);
$primary-light: #0074bd;
$action-light: #0074bd;
$affirmation-light: #007c8a;
$info-light: #005f9e;
$caution-light: #f8a72d;
$positive-light: #108043;
$negative-light: #d91014;
$success-light: $positive-light;
$warning-light: $caution-light; // #ff8d72 !default;
$danger-light: $negative-light;
$error-light: $negative-light;
$light-light: #f2f8fc;
$secondary-light: #f4f5f7 !default;
$action-rgb: 0, 116, 189;
$breadcrumb-light: #101e26;

$opacity-light: () !default;
$opacity-light: map-merge(
  (
    'action': (
      '5': rgba(0, 116, 189, 0.05),
      '10': rgba(0, 116, 189, 0.1),
      '25': rgba(0, 116, 189, 0.25),
      '50': rgba(0, 116, 189, 0.5),
      '55': rgba(0, 116, 189, 0.55)
    ),
    'affirmation': (
      '5': rgba(0, 124, 138, 0.05),
      '10': rgba(0, 124, 138, 0.1),
      '25': rgba(0, 124, 138, 0.25),
      '50': rgba(0, 124, 138, 0.5),
      '55': rgba(0, 124, 138, 0.55)
    ),
    'positive': (
      '5': rgba(16, 128, 67, 0.05),
      '10': rgba(16, 128, 67, 0.1),
      '25': rgba(16, 128, 67, 0.25),
      '50': rgba(16, 128, 67, 0.5),
      '55': rgba(16, 128, 67, 0.55)
    ),
    'negative': (
      '5': rgba(217, 16, 20, 0.05),
      '10': rgba(217, 16, 20, 0.1),
      '25': rgba(217, 16, 20, 0.25),
      '50': rgba(217, 16, 20, 0.5),
      '55': rgba(217, 16, 20, 0.55)
    ),
    'caution': (
      '5': rgba(248, 167, 45, 0.05),
      '10': rgba(248, 167, 45, 0.1),
      '25': rgba(248, 167, 45, 0.25),
      '50': rgba(248, 167, 45, 0.5),
      '55': rgba(248, 167, 45, 0.55)
    )
  ),
  $opacity-light
);

$action-5-light: rgba(0, 116, 189, 0.05);
$action-10-light: rgba(0, 116, 189, 0.1);
$action-25-light: rgba(0, 116, 189, 0.25);
$action-50-light: rgba(0, 116, 189, 0.5);
$action-55-light: rgba(0, 116, 189, 0.55);
$caution-5-light: rgba(248, 167, 45, 0.05);
$caution-10-light: rgba(248, 167, 45, 0.1);
$caution-25-light: rgba(248, 167, 45, 0.25);
$caution-50-light: rgba(248, 167, 45, 0.5);
$negative-5-light: rgba(217, 16, 20, 0.05);
$negative-10-light: rgba(217, 16, 20, 0.1);
$negative-15-light: rgba(217, 16, 20, 0.15);
$negative-25-light: rgba(217, 16, 20, 0.25);
$negative-50-light: rgba(217, 16, 20, 0.5);
$positive-5-light: rgba(16, 128, 67, 0.05);
$positive-10-light: rgba(16, 128, 67, 0.1);
$positive-15-light: rgba(16, 128, 67, 0.15);
$positive-25-light: rgba(16, 128, 67, 0.25);
$positive-50-light: rgba(16, 128, 67, 0.5);
$affirmation-25-light: rgba(0, 124, 138, 0.25);
$info-10-light: rgba(0, 95, 158, 0.1);

$utility-colors-light: () !default;
$utility-colors-light: map-merge(
  (
    'action': $action-light,
    'affirmation': $affirmation-light,
    'information': $info-light,
    'caution': $caution-light,
    'positive': $positive-light,
    'negative': $negative-light,
    'error': $error-light,
    'light': $light-light
  ),
  $utility-colors-light
);

$text-hint-light: $black-55;
$text-disabled-light: $black-25;

$bg-default-light: #ffffff;
$bg-light-light: #fafafa;
$bg-dark-light: #f5f5f5;
$bg-shade-light: $black-55;
$bg-contrast-default-light: #343741;

$mu-default-light: #ffffff;
$mu-shade-light: #f5f5f5;
$mu-solid-light: #004671;

$web-divider-light: $black-15;
$input-divider-light: $web-divider-light;

$btn-primary-text-light: #ffffff;
$btn-secondary-text-light: $white-75;

$info-a--3-light: #edf6f7;
$info-a--2-light: #cce6e9;
$info-a--1-light: #98d3da;
$info-a-light: #339aa5;
$info-a-1-light: #00737f;
$info-a-2-light: #004850;
$info-a-3-light: #002d31;

$info-b--3-light: #ffecf0;
$info-b--2-light: #ffb0c2;
$info-b--1-light: #ff6185;
$info-b-light: #ff3a67;
$info-b-1-light: #c9294e;
$info-b-2-light: #901632;
$info-b-3-light: #64041b;

$info-c--3-light: #faf3fd;
$info-c--2-light: #eacaf7;
$info-c--1-light: #cb93e2;
$info-c-light: #b66ad6;
$info-c-1-light: #9833c2;
$info-c-2-light: #672b80;
$info-c-3-light: #37034d;

$info-d--3-light: #f4f9f1;
$info-d--2-light: #cee4c4;
$info-d--1-light: #75a95d;
$info-d-light: #519d2f;
$info-d-1-light: #3e6f27;
$info-d-2-light: #22480f;
$info-d-3-light: #133105;

$info-e--3-light: #fef5ee;
$info-e--2-light: #fcd3b7;
$info-e--1-light: #fd8940;
$info-e-light: #e3620b;
$info-e-1-light: #b64f0a;
$info-e-2-light: #853804;
$info-e-3-light: #4f2000;

$info-f--3-light: #f3f4fe;
$info-f--2-light: #cbd2f9;
$info-f--1-light: #9eabf3;
$info-f-light: #7083e7;
$info-f-1-light: #485dd2;
$info-f-2-light: #2d3a84;
$info-f-3-light: #08124b;

$info-g--3-light: #f3f9fe;
$info-g--2-light: #cbe0f9;
$info-g--1-light: #9ec0f3;
$info-g-light: #0074bd;
$info-g-1-light: #0168ad;
$info-g-2-light: #105180;
$info-g-3-light: #082f4b;

$info-h--3-light: #f1f1f1;
$info-h--2-light: #dddddd;
$info-h--1-light: #c0c0c0;
$info-h-light: #7e7e7e;
$info-h-1-light: #676767;
$info-h-2-light: #414141;
$info-h-3-light: #252525;

$home-bg-light: #c4d8dd;

$link-color-light: $primary-light !default;
$link-active-light: #1200ff;
$link-decoration-light: none !default;
$link-hover-decoration-light: none !default;
$link-breadcrumb-slink-light: #737373;

$card-panel-dark-gradient-light: #cce3f2;
$card-panel-read-more-gradient-light: #e1f1f9;

$grid-border-color-light: rgba(33, 37, 41, 0.125);
$grid-pager-bg-light: #f8f9fa;

$loader-overlay-bg-light: rgba(194, 194, 194, 0.6);
$loader-scroll-bg-light: #ced4da;

$risk-tolerance-bg-light: #8fb5bf;

$workbench-action-light-light: $primary-light;
$workbench-action-dark-light: #01385b;
$workbench-action-hover-light-light: #03446d;
$workbench-action-hover-dark-light: #011f31;
$workbench-action-border-light: #c7c7c7;
$workbench-transact-icon-light: #f8a72d;

$tiered-menu-active-bg-light: #eff6ff;

$compare-allocation-bg-light: #d9d9d9;
$compare-allocation-meter-boxshadow-light: #bfbfbf;

$toast-caution-bg-light: #fff2e2;
$toast-caution-text-light: #c97f11;
$toast-negative-bg-light: #f3e2e2;
$toast-information-bg-light: #d6e8f3;

$filters-tab-bg-light: #ced4da; // #d3d3d3;

$category-1-bg-light: #2483c5;
$category-2-bg-light: #002237;
$category-3-bg-light: #d8e1ea;
$category-1-text-light: $bg-default-light;
$category-2-text-light: $bg-default-light;
$category-3-text-light: $default-light;

$carousel-button-disabled-light: #d8e1ea;

// IX COLOR VARIBLES
$ix-blue-secondary: #2483c5;

// Pixel variables
$card-margin-spacing: 8px;
