@use 'sass:math';

// DEFAULT COLORS - TBD IF NEEDED
$black: #000000;
$black-num: '0 0 0';
$white-num: '255 255 255';
$black-2: rgba(0, 0, 0, 0.02);
$black-4: rgba(0, 0, 0, 0.04);
$black-5: rgba(0, 0, 0, 0.05);
$black-8: rgba(0, 0, 0, 0.08);
$black-10: rgba(0, 0, 0, 0.1);
$black-15: rgba(0, 0, 0, 0.15);
$black-25: rgba(0, 0, 0, 0.25);
$black-42: rgba(0, 0, 0, 0.42);
$black-55: rgba(0, 0, 0, 0.55);
$black-70: rgba(0, 0, 0, 0.7);

$white: #ffffff;
$white-55: rgba(255, 255, 255, 0.55);
$white-75: rgba(255, 255, 255, 0.75);

$light-background: #f5f6fa !default;
$dark-background: #1e1e2a !default;

$yellow: #f59e0b !default;

$default: #344675 !default;
$primary: #0074bd !default;
$secondary: #f4f5f7 !default;
$success: #00f2c3 !default;
$info: #007c8a !default;
$warning: #ff8d72 !default;
$danger: #d91014 !default;
$black: #222a42 !default;

$primary-blue: #005faa !default;

$danger-states: #ec250d !default;
$black-states: #1d253b !default;

// FONT VARIABLES
$font-family-base: Lato, sans-serif !default;
$lato: 'Lato', sans-serif;
$default-font: $lato;

$font-size-dash: 0.75rem !default;

$font-size-base: $font-size-dash !default; // Assumes the browser default, typically `16px`
$font-size-xl: ($font-size-base * 1.428);
$font-size-lg: ($font-size-base * 1.06667); // 0.8rem
$font-size-sm: ($font-size-base * 0.866); // 0.65 rem
$font-size-xs: ($font-size-base * 0.714);

// Font sizes
$default-font-size: 14px;
$small-font-size: 12px;
$medium-font-size: 16px;
$large-font-size: 20px;
$xl-font-size: 48px;

$h1-font-size: 36px;
$h2-font-size: 28px;
$h3-font-size: 20px;
$h4-font-size: 16px;
$h5-font-size: 14px;
$h6-font-size: 12px;

$body-line-height: 17px;
$medium-line-height: 19px;
$navbar-button-ml: 10px;

// Form variables
$form-border-radius: 6px;
$form-border-size: 1px;
$form-border-size-alert: 2px;
$form-box-shadow-sr: 4px;
$form-control-height: 36px;
$form-control-padding: 10px 12px 9px 12px;
$form-control-line-height: 17px;
$form-label-padding: 0 0 8px 0;
$form-label-padding-inline: 0;
$form-toggle-border: 2px;
$form-toggle-icon-off: '\f00d';
$form-toggle-icon-on: '\f00c';
$form-toggle-icon-size: 13px;
$form-toggle-font-weight: 900;
$form-toggle-line-height: 21px;
$form-toggle-width: 40px !important;
$form-toggle-height: 24px !important;
$form-toggle-translate-x: 17px;

$btn-text-lg: 20px;
$btn-text-md: 14px;
$btn-text-sm: 12px;
$btn-lh-lg: 24px;
$btn-lh-md: 14px;
$btn-lh-sm: 12px;

$btn-height-lg: 46px;
$btn-height-md: 36px;
$btn-height-sm: 24px;

$btn-padding-y: 9.5px;
$btn-padding-x: 16px;

$btn-padding-y-sm: 4.5px;
$btn-padding-x-sm: 12px;

$btn-padding-y-lg: 11px;
$btn-padding-x-lg: 30px;

$btn-box-shadow-default:
  0 4px 6px rgb(50 50 93 / 11%),
  0 1px 3px rgb(0 0 0 / 8%);
$btn-box-shadow-values: 0 0 0 4px;
$btn-border-radius: 6px;
$btn-margin-all: 1px;

$card-border-size: 1px;
$card-box-shadow-1: 0 2px 1px -1px;
$card-box-shadow-2: 0 1px 1px 0;
$card-box-shadow-3: 0 1px 3px 0;
$card-box-shadow-global-1: 0 2px 1px -1px;
$card-box-shadow-global-2: 0 1px 1px 0;
$card-box-shadow-global-3: 0 1px 3px 0;

$favorite-star-size: 27px;
$favorite-star-color-unfilled: #ccc9c9;
$favorite-star-color-filled: #f8a72d;

$product-training-padding: 12px;
$product-training-mb: 8px;
$product-training-header-mb: 4px;
$product-training-color-background: #f9e5c6;

// PrimeNg Styles
$primeng-btn-padding: 6px 12px;
$primeng-btn-padding-icon: 6px;
$primeng-btn-loading-mt: -8px;
$primeng-btn-loading-mr: 8px;

$primeng-dialog-box-shadow: 0px 2px 20px;
$primeng-dialog-padding: 32px;
$primeng-dialog-padding-header: 6px 12px;
$primeng-dialog-font-header: 24px;
$primeng-dialog-icon-header-size: 36px;
$primeng-dialog-content-padding: 0px 24px !important;
$primeng-dialog-content-max-height: 400px;
$primeng-dialog-content-icon-header-size: 36px;
$primeng-dialog-popup-width: '50vw';

$primeng-confirm-reject-mr: 8px;
$primeng-confirm-reject-mb: 0px;

$primeng-breadcrumb-border: none !important;
$primeng-breadcrumb-font-size: 12px;
$primeng-breadcrumb-icon-size: 14px;
$primeng-breadcrumb-main-margin: 0;
$primeng-breadcrumb-main-padding: 6px 0px 2px;
$primeng-breadcrumb-main-line-height: 16px;
$primeng-breadcrumb-main-right-icon-line-height: 14px;
$primeng-breadcrumb-separator-width: 12px;
$primeng-breadcrumb-color-last-child: $black-states;
$primeng-breadcrumb-mobile-icon-margin: 0 3.2px;

$primeng-calendar-box-shadow-1: 4px 0 0 0;
$primeng-calendar-box-shadow-2: 0 -2px 0 4px;
$primeng-calendar-box-shadow-3: 0 2px 0 4px;
$primeng-calendar-input-right-radius: 0px;
$primeng-button-padding: 6px;

$primeng-checkbox-width: 20px;
$primeng-checkbox-height: 20px;
$primeng-checkbox-font-size: $small-font-size;
$primeng-checkbox-line-height: 14px;

$primeng-confirm-box-shadow: 0px 2px 20px;

$primeng-dropdown-control-pl: 9px;
$primeng-dropdown-input-line-height: 14px;
$primeng-dropdown-input-padding: 0px 0px 0px 3px;
$primeng-dropdown-trigger-width: 16px;
$primeng-dropdown-filter-wrapper-top: 2px;
$primeng-dropdown-filter-icon-right: 12px;
$primeng-dropdown-panel-filter-pr: 28px;
$primeng-dropdown-panel-filter-mr: -28px;
$primeng-dropdown-items-pl: 0px;
$primeng-dropdown-item-padding: 8px 12px;
$primeng-dropdown-item-not-highlight-pl: 19px;
$primeng-dropdown-item-select-icon-pr: 6.3px;
$primeng-dropdown-error-pt: 9px;
$primeng-dropdown-error-pb: 9px;
$primeng-dropdown-subnavbar-width: 24px !important;

$primeng-listbox-list-pl: 0px;
$primeng-listbox-item-padding: 8px 12px;

$primeng-multiselect-control-padding: 0px 12px 0px 9px;
$primeng-multiselect-label-padding: 8px 0px 0px 0px;
$primeng-multiselect-panel-header-padding: 10px;
$primeng-multiselect-filter-container-pr: 28px;
$primeng-multiselect-filter-container-mb: 0px;
$primeng-multiselect-filter-container-mr: -16px;
$primeng-multiselect-error-pl: 8px;
$primeng-multiselect-error-label-pt: 7px;

$primeng-pagination-padding: 4px;
$primeng-pagination-links-padding: 3px;
$primeng-pagination-current-height: 32px;
$primeng-pagination-page-padding: 2px 6px;
$primeng-pagination-page-width: 22px;
$primeng-pagination-page-height: 22px;
$primeng-pagination-dropdown-margin: 3.2px;
$primeng-pagination-dropdown-padding: 6px 8px;

$primeng-radio-width: 20px;
$primeng-radio-height: 20px;
$primeng-radio-box-shadow: 0 0 0 4px;

$primeng-table-border-size: 1px;
$primeng-table-border-header-size: 2px;
$primeng-table-header-border-top: 0px;
$primeng-table-header-padding: 0px;
$primeng-table-search-container-padding: 0px 8px;
$primeng-table-head-first-border-left: none;
$primeng-table-body-row-last-child-border-bottom: 0px;
$primeng-table-paginator-border-width: 1px 0 0 0;
$primeng-table-small-header-padding: 0px;
$primeng-table-small-body-td-padding: 6px;
$primeng-table-small-body-td-empty-pl: 10px;
$primeng-table-responsive-column-border: 0 none;
$primeng-table-responsive-column-title-margin: -6.4px 16px -6.4px -6.4px;
$primeng-table-responsive-column-title-padding: 6.4px;

$primeng-tabs-border: none;
$primeng-tabs-pl: 0px;
$primeng-tabs-link-padding: 12px 20px 12px 20px;
$primeng-tabs-link-highlight-border-bottom: 6px solid;

$primeng-tieredmenu-list-pl: 0px;
$primeng-tieredmenu-list-mb: 0px;
$primeng-tieredmenu-list-top: -4px;
$primeng-tieredmenu-splitbutton-padding: 8px;

$primeng-upload-padding: 20px 10px;

$primeng-input-icon-left-mt: -10px;
$primeng-input-icon-form-control-pl: 30px;

//Font weight
$font-weight-400: 400;
$font-weight-500: 500;
$font-weight-700: 700;
$font-weight-800: 800;
$font-weight-normal: 400;
$font-weight-strong: 500;
$font-weight-bold: 700;

$scrollbar-width: 5px;
$scrollbar-height: 7px;
$scrollbar-button: 0px;
$scrollbar-thumb-width: 0px;
$scrollbar-thumb-border-radius: 5px;

$bottom-button-container-box-shadow: 0 -5px 15px;

// LAYOUT VARIABLES
$full-width: 100% !default;
$full-height: 100% !default;

$landing-page-banner-text: 80px;
$landing-page-banner-pt: 64px;

$spacing-xl: 32px;
$spacing-lg: 24px;
$spacing-md: 16px;
$spacing-rg: 12px;
$spacing-sm: 8px;
$spacing-xs: 4px;
