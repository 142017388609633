::-webkit-scrollbar {
  width: $scrollbar-width;
  height: $scrollbar-height;

  &-button {
    width: $scrollbar-button;
    height: $scrollbar-button;
  }

  &-thumb {
    background: var(--fidx-color-scrollbar-thumb-bg, $black-25);
    border: $scrollbar-thumb-width none var(--fidx-color-scrollbar-thumb-border, $bg-default-light);
    border-radius: $scrollbar-thumb-border-radius;
    &:hover,
    &:active {
      background: var(--fidx-color-scrollbar-thumb-active-bg, $action-light);
    }
  }

  &-track {
    background: var(--fidx-color-scrollbar-track-bg, $white);
    border: $scrollbar-thumb-width none var(--fidx-color-scrollbar-track-bg, $bg-default-light);
    border-radius: $scrollbar-thumb-border-radius;
    &:hover,
    &:active {
      background: transparent;
    }
  }

  &-corner {
    background: transparent;
  }
}

[data-theme='dark'] {
  ::-webkit-scrollbar {
    &-thumb {
      background: $input-divider-dark;
      border-color: $bg-default-dark;
      &:hover,
      &:active {
        background: $action-dark;
      }
    }

    &-track {
      background: $bg-default-dark;
      border: $scrollbar-thumb-width none $bg-default-dark;
      border-radius: $scrollbar-thumb-border-radius;
      &:hover,
      &:active {
        background: $bg-default-dark;
      }
    }
  }
}
