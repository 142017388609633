p-dropdown.dropdown-disabled,
.p-radiobutton-disabled {
  cursor: no-drop;
}

// Case Actions
.case-actions,
.case-illustration-actions {
  &.p-tieredmenu {
    &.p-tieredmenu-overlay {
      background: var(--fidx-color-dropdown-list-container-bg, $white);
      border-color: var(--fidx-color-form-control-border, $black-15);
    }
    .p-menuitem.p-menuitem-active > .p-menuitem-link {
      background: var(--fidx-color-tieredmenu-active-bg, $tiered-menu-active-bg-light);
      .p-menuitem-text {
        color: var(--fidx-color-utility-action, $action-light);
      }
    }
    .p-menuitem:not(.action-header):not(.p-disabled) {
      > .p-menuitem-content {
        background: var(--fidx-color-dropdown-list-container-bg, $white);
        font-family: $default-font;
        font-size: $default-font-size;
        .p-menuitem-link,
        .p-menuitem-link .p-menuitem-text {
          color: var(--fidx-color-text-default, $default-light);
        }
        &:hover {
          color: var(--fidx-color-utility-action, $action-light);
          background: var(--fidx-color-tieredmenu-active-bg, $tiered-menu-active-bg-light) !important;
          .p-menuitem-link .p-menuitem-text {
            color: var(--fidx-color-utility-action, $action-light);
          }
          .p-menuitem-link:hover {
            text-decoration-color: var(--fidx-color-utility-action, $action-light);
          }
        }
      }
    }
    ul {
      padding-left: $primeng-tieredmenu-list-pl;
      margin-bottom: $primeng-tieredmenu-list-mb;
      position: relative;
      top: $primeng-tieredmenu-list-top;

      li.action-header {
        border-radius: $form-border-radius $form-border-radius 0px 0px;
        font-weight: bold;
        background: var(--fidx-color-utility-action, $action-light) !important;
        opacity: 1;
        .p-menuitem-link {
          opacity: 1;
          .p-menuitem-text {
            color: var(--fidx-color-btn-primary-text, $white);
          }
        }
      }
    }
  }
  .p-splitbutton-defaultbutton {
    display: none;
  }
  .p-splitbutton-menubutton {
    border-radius: $form-border-radius;
    padding: $primeng-tieredmenu-splitbutton-padding;
    width: auto;
  }
}

.case-illustration-actions {
  text-align: left;
}

.p-tooltip .p-tooltip-text {
  font-family: $lato;
  font-size: 12px;
}
