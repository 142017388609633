.card {
  background: var(--fidx-color-card-bg, $white);
  border: $card-border-size solid var(--fidx-color-card-border, $black-15);
  box-shadow:
    $card-box-shadow-1 rgb(var(--fidx-color-shadow-num, $black-num) / 20%),
    $card-box-shadow-2 rgb(var(--fidx-color-shadow-num, $black-num) / 14%),
    $card-box-shadow-3 rgb(var(--fidx-color-shadow-num, $black-num) / 12%);
  color: var(--fidx-color-text-default, $default-light);

  &.full-height {
    height: $full-height;
  }
}

.card-header {
  background-color: transparent;
  border-bottom: none;
}
