.favorite-star {
  --star-size: #{$favorite-star-size};
  --star-filled: #{$favorite-star-color-filled};
  --star-unfilled: #{$favorite-star-color-unfilled};

  cursor: pointer;
  font-size: var(--star-size, $favorite-star-size);
  color: var(--star-unfilled, $favorite-star-color-unfilled);

  &.filled {
    color: var(--star-filled, $favorite-star-color-filled);
  }
}
