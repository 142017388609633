.p-dropdown {
  background-color: var(--fidx-color-form-control-bg, $bg-default-light);
  color: var(--fidx-color-dropdown-text, $default-light);
  border: $form-border-size solid var(--fidx-color-form-control-border, $black-15);
  &.form-control {
    display: inline-flex;
    padding-left: $primeng-dropdown-control-pl;
  }
  &.read-only,
  &:not(.p-disabled).read-only {
    background: var(--fidx-color-form-control-readonly-bg, $bg-dark-light);
    &:hover {
      background: var(--fidx-color-form-control-readonly-bg, $bg-dark-light);
      border: $form-border-size solid var(--fidx-color-form-control-readonly-border, $black-55);
    }
    &:focus-visible {
      outline: 0;
      border: $form-border-size-alert solid var(--fidx-color-form-control-readonly-border, $black-55);
    }
  }
  .p-inputtext {
    color: var(--fidx-color-dropdown-text, $default-light);
    font-family: $default-font;
    font-size: $default-font-size;
    line-height: $primeng-dropdown-input-line-height;
    padding: $primeng-dropdown-input-padding;
  }
  .p-dropdown-trigger {
    width: $primeng-dropdown-trigger-width;
    font-size: $default-font-size;
    color: var(--fidx-color-dropdown-trigger, $black-15);
    &[aria-expanded='true'] {
      span.p-dropdown-trigger-icon {
        transition: transform 250ms;
        transform: rotate(180deg) translateY(2px);
      }
    }
    &[aria-expanded='false'] {
      span.p-dropdown-trigger-icon {
        transition: transform 250ms;
        transform: rotate(0deg) translateY(0px);
      }
    }
  }
  .p-dropdown-label {
    &.p-placeholder {
      color: var(--fidx-color-form-placeholder, $black-55);
    }
  }
}

.p-dropdown-filter-container {
  .p-inputtext {
    @extend .form-control !optional;
  }
  .p-icon-wrapper {
    position: relative;
    top: $primeng-dropdown-filter-wrapper-top;
  }
  .p-dropdown-filter-icon {
    position: relative;
    right: $primeng-dropdown-filter-icon-right;
  }
}

.p-dropdown-items-wrapper {
  background: var(--fidx-color-dropdown-list-container-bg, $white);
  color: var(--fidx-color-dropdown-text, $default-light);
  font-size: $default-font-size;
  border-bottom-left-radius: $form-border-radius;
  border-bottom-right-radius: $form-border-radius;
}

.p-dropdown-panel {
  background: var(--fidx-color-dropdown-list-container-bg, $white);
  .p-dropdown-header {
    background: var(--fidx-color-dropdown-list-container-bg, $white);
    border-bottom-color: var(--fidx-color-form-control-border, $black-15);
    .p-dropdown-filter {
      padding-right: $primeng-dropdown-panel-filter-pr;
      margin-right: $primeng-dropdown-panel-filter-mr;
    }
  }
  .p-dropdown-items {
    font-family: $default-font;
    padding-left: $primeng-dropdown-items-pl;
    .p-dropdown-item {
      padding: $primeng-dropdown-item-padding;

      &.p-highlight {
        color: var(--fidx-color-dropdown-text, $default-light);
        background: transparent;
        font-weight: $font-weight-bold;
      }

      &:not(.p-highlight) {
        color: var(--fidx-color-dropdown-text, $default-light);
        &.p-focus {
          background: var(--fidx-color-dropdown-list-item-hover-bg, $light-light);
        }
        .template {
          padding-left: $primeng-dropdown-item-not-highlight-pl;
        }
        &:not(.p-disabled) {
          &.p-focus {
            background: var(--fidx-color-dropdown-list-item-hover-bg, $light-light);
          }
          &:hover {
            color: var(--fidx-color-dropdown-text, $default-light);
            background: var(--fidx-color-dropdown-list-item-hover-bg, $light-light);
          }
        }
      }

      .selection-icon {
        padding-right: $primeng-dropdown-item-select-icon-pr;
      }
    }
  }
}

.p-element.ng-touched.ng-invalid {
  .p-dropdown {
    @extend .has-error !optional;
    padding-top: $primeng-dropdown-error-pt;
    padding-bottom: $primeng-dropdown-error-pb;
    @extend %FIDx-has-error-attributes !optional;
  }
}

.p-column-filter-operator-dropdown,
.p-column-filter-matchmode-dropdown {
  &.p-dropdown {
    @extend %form-control-styles !optional;
  }
}

@media screen and (max-width: 767px) {
  .subnavbar-sticky {
    .p-dropdown .p-dropdown-trigger {
      width: $primeng-dropdown-subnavbar-width;
    }
  }
}
