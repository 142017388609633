@import './variables';

[data-theme='dark'] {
  --fidx-color-shadow-num: #{$white-num};

  --fidx-color-bg: #{$bg-default-dark};
  --fidx-color-text-default: #{$default-dark};
  --fidx-color-text-link: #{$link-color-dark};
  --fidx-color-text-hint: #{$text-hint-dark};

  --fidx-color-nav-link: #{$white};
  --fidx-color-nav-link-hover: #{$text-hint-dark};

  --fidx-color-required: #{$negative-dark};
  --fidx-color-error: #{$negative-dark};
  --fidx-color-warning: #{$caution-dark};

  --fidx-color-scrollbar-thumb-active-bg: #{$action-dark};

  --fidx-color-container-bg: #{$mu-shade-dark};

  @each $utility in $utilities {
    --fidx-color-utility-#{$utility}: #{map-get($utility-colors-dark, $utility)};
  }

  --fidx-color-has-error-border: #{$negative-dark};
  --fidx-color-has-error-border-faded: #{$negative-25-dark};
  --fidx-color-has-warning-border: #{$negative-dark};
  --fidx-color-has-warning-focus-faded: #{$negative-25-dark};

  // BTN VARIABLES
  --fidx-color-btn-primary-bg: #{$action-dark};
  --fidx-color-btn-primary-text: #{$bg-default-dark};
  --fidx-color-btn-primary-weight: 700;
  --fidx-color-btn-primary-hover-bg-grad: #{$black-5};
  --fidx-color-btn-primary-hover-bg-layer: #{$action-dark};
  --fidx-color-btn-primary-hover-border: #{$action-dark};
  --fidx-color-btn-primary-active-bg-grad: #{$black-10};
  --fidx-color-btn-primary-active-bg-layer: #{$action-dark};
  --fidx-color-btn-primary-active-border: #{$action-dark};
  --fidx-color-btn-primary-active-hover-border: #{$action-dark};
  --fidx-color-btn-primary-focus-boxshadow: #{$action-25-dark};
  --fidx-color-btn-primary-disabled-bg: #{$action-50-dark};

  @each $class, $section in $btn-map-section {
    --fidx-color-btn-primary-#{$class}-bg: #{map-get($utility-colors-dark, $section)};
    --fidx-color-btn-primary-#{$class}-hover-bg-grad: #{$black-5};
    --fidx-color-btn-primary-#{$class}-border: #{map-get($utility-colors-dark, $section)};
    --fidx-color-btn-primary-#{$class}-hover-bg-layer: #{map-get($utility-colors-dark, $section)};
    --fidx-color-btn-primary-#{$class}-hover-border: #{map-get($utility-colors-dark, $section)};
    --fidx-color-btn-primary-#{$class}-active-bg-grad: #{$black-10};
    --fidx-color-btn-primary-#{$class}-active-bg-layer: #{map-get($utility-colors-dark, $section)};
    --fidx-color-btn-primary-#{$class}-active-border: #{map-get($utility-colors-dark, $section)};
    --fidx-color-btn-primary-#{$class}-active-hover-border: #{map-get($utility-colors-dark, $section)};
    --fidx-color-btn-primary-#{$class}-text: #{$bg-default-dark};
    --fidx-color-btn-primary-#{$class}-weight: 700;
    --fidx-color-btn-primary-#{$class}-boxshadow: #{map-get(map-get($opacity-dark, $section), '25')};
    --fidx-color-btn-primary-#{$class}-disabled: #{map-get(map-get($opacity-dark, $section), '50')};
    --fidx-color-btn-primary-#{$class}-disabled-bg: #{map-get(map-get($opacity-dark, $section), '50')};
  }

  --fidx-color-btn-secondary-bg: #{$bg-default-dark};
  --fidx-color-btn-secondary-border: #{$web-divider-dark};
  --fidx-color-btn-secondary-text: #{$action-dark};
  --fidx-color-btn-secondary-weight: 700;
  --fidx-color-btn-secondary-hover-border: #{$web-divider-dark};
  --fidx-color-btn-secondary-hover-bg-grad: #{$action-5-dark};
  --fidx-color-btn-secondary-hover-bg-layer: #{$bg-default-dark};
  --fidx-color-btn-secondary-active-border: #{$action-dark};
  --fidx-color-btn-secondary-active-bg-grad: #{$action-10-dark};
  --fidx-color-btn-secondary-active-bg-layer: #{$bg-default-dark};
  --fidx-color-btn-secondary-focus-boxshadow: #{$action-25-dark};
  --fidx-color-btn-secondary-disabled-bg: #{$bg-default-dark};
  --fidx-color-btn-secondary-disabled-border: #{$black-15};

  @each $class, $section in $btn-map-section {
    --fidx-color-btn-secondary-#{$class}-bg: #{$bg-default-dark};
    --fidx-color-btn-secondary-#{$class}-border: #{map-get(map-get($opacity-dark, $section), '50')};
    --fidx-color-btn-secondary-#{$class}-text: #{map-get($utility-colors-dark, $section)};
    --fidx-color-btn-secondary-#{$class}-weight: #{$font-weight-bold};
    --fidx-color-btn-secondary-#{$class}-hover-border: #{map-get(map-get($opacity-dark, $section), '50')};
    --fidx-color-btn-secondary-#{$class}-hover-bg-grad: #{map-get(map-get($opacity-dark, $section), '5')};
    --fidx-color-btn-secondary-#{$class}-hover-bg-layer: #{$bg-default-dark};
    --fidx-color-btn-secondary-#{$class}-active-border: #{map-get($utility-colors-dark, $section)};
    --fidx-color-btn-secondary-#{$class}-active-bg-grad: #{map-get(map-get($opacity-dark, $section), '10')};
    --fidx-color-btn-secondary-#{$class}-active-bg-layer: #{$bg-default-dark};
    --fidx-color-btn-secondary-#{$class}-focus-boxshadow: #{map-get(map-get($opacity-dark, $section), '25')};
    --fidx-color-btn-secondary-#{$class}-disabled-bg: #{$bg-default-dark};
    --fidx-color-btn-secondary-#{$class}-disabled-border: #{map-get($utility-colors-dark, $section)};
  }

  --fidx-color-btn-textbtn-bg: transparent;
  --fidx-color-btn-textbtn-border: transparent;
  --fidx-color-btn-textbtn-text: #{$default-dark};
  --fidx-color-btn-textbtn-weight: #{$font-weight-bold};

  // FORM CONTROLS
  --fidx-color-form-input-container: #{$bg-default-dark};
  --fidx-color-form-placeholder: #{$text-hint-dark};
  --fidx-color-form-control-text: #{$white};
  --fidx-color-form-control-bg: #{$bg-default-dark};
  --fidx-color-form-control-border: #516578;
  --fidx-color-form-control-hover-border: #{$action-dark};
  --fidx-color-form-control-focus-bg: #{$bg-default-dark};
  --fidx-color-form-control-focus-text: #{$default-dark};
  --fidx-color-form-control-focus-visible: #{$action-dark};
  --fidx-color-form-control-focus-boxshadow: #{$action-25-dark};
  --fidx-color-form-control-readonly-bg: #{$bg-dark-dark};
  --fidx-color-form-control-readonly-border: #{$black-55};
  --fidx-color-form-control-disabled-bg: #{$bg-dark-dark};
  --fidx-color-form-control-disabled-border: #{$bg-default-dark};
  --fidx-color-form-control-disabled-hover-border: transparent;
  --fidx-color-form-control-disabled-text: #{$text-hint-dark};
  --fidx-color-form-control-selection: #{$action-55-dark};
  --fidx-color-form-input-hidden-bg: #{$bg-default-dark};
  --fidx-color-form-error-bg: #{$bg-default-dark};
  --fidx-color-form-warning-bg: #{$bg-default-dark};
  --fidx-color-form-control-outline-bg: #{$bg-default-dark};
  --fidx-color-form-control-outline-border-total: 1px solid #516578;

  --fidx-color-form-check-radio-bg: #{$bg-default-dark};
  --fidx-color-form-check-radio-border: #{$input-divider-dark};
  --fidx-color-form-check-radio-border-focus: #{$action-dark};
  --fidx-color-form-check-radio-focus-boxshadow: #{$action-25-dark};
  --fidx-color-form-check-radio-checked-text: #{$bg-default-dark};
  --fidx-color-form-check-radio-checked-border: #{$action-dark};
  --fidx-color-form-check-radio-checked-bg: #{$action-dark};
  --fidx-color-form-check-radio-checked-focus-border: #{$action-dark};
  --fidx-color-form-check-radio-checked-focus-boxshadow: #{$action-25-dark};
  --fidx-color-form-check-radio-disabled-text: #{$black-25};
  --fidx-color-form-check-radio-disabled-bg: #{$bg-dark-dark};
  --fidx-color-form-check-radio-disabled-border: #{$bg-default-dark};

  --fidx-color-datepicker-content-text: #{$action-dark};
  --fidx-color-datepicker-selected-bg: #{$action-dark};
  --fidx-color-datepicker-selected-text: #{$bg-default-dark};
  --fidx-color-datepicker-bg-hover: #{$action-25-dark};
  --fidx-color-datepicker-text-hover: #{$text-hint-dark};
  --fidx-color-form-control-datepicker-border: #516578;
  --fidx-color-form-control-datepicker-selection-bg: #{$action-55-dark};
  --fidx-color-form-control-datepicker-focus-border: #{$action-dark};
  --fidx-color-form-control-datepicker-focus-boxshadow: #{$action-25-dark};
  --fidx-color-form-control-datepicker-button-bg: #{$bg-default-dark};
  --fidx-color-form-control-datepicker-button-color: #{$action-dark};
  --fidx-color-form-control-datepicker-today-color: #{$action-dark};
  --fidx-color-form-control-datepicker-today-hover-color: #{$action-dark};
  --fidx-color-form-control-datepicker-action-border: #{$action-dark};
  --fidx-color-form-control-datepicker-selected-bg: #{$bg-default-dark};
  --fidx-color-form-control-datepicker-calendar-bg: #{$bg-default-dark};
  --fidx-color-form-control-datepicker-calendar-text: #{$white};

  --fidx-color-form-button-component-footer-bg: #{$white};
  --fidx-color-form-button-component-footer-boxshadow: #{$black-10};
  --fidx-color-form-control-selector-group-error-text: #{$negative-dark};
  --fidx-color-form-control-selector-group-error-bg: #{$white};
  --fidx-color-form-control-selector-group-disabled-text: #{$black-25};

  // FOOTER
  --fidx-color-form-button-component-footer-bg: #{$bg-default-dark};
  --fidx-color-form-button-component-footer-boxshadow: #{$black-10};

  // DROPDOWN VARIABLES
  --fidx-color-dropdown-text: #{$white};
  --fidx-color-dropdown-bg: #{$bg-default-dark};
  --fidx-color-dropdown-border: #{$action-dark};
  --fidx-color-dropdown-boxshadow: #{$action-25-dark};
  --fidx-color-dropdown-input-selection-bg: #{$action-55-dark};
  --fidx-color-dropdown-animation-boxshadow: #{$black-8};
  --fidx-color-dropdown-list-container-bg: #{$bg-default-dark};
  --fidx-color-dropdown-list-item-hover-bg: #{$bg-light-dark};
  --fidx-color-dropdown-list-item-selected-bg: #{$bg-default-dark};
  --fidx-color-dropdown-list-item-selected-text: #{$white};
  --fidx-color-dropdown-button-text: #{$white};
  --fidx-color-dropdown-button-bg: #{$bg-default-dark};
  --fidx-color-dropdown-button-select-border: #{$black-15};
  --fidx-color-dropdown-trigger: #{$text-hint-dark};

  --fidx-color-dropdown-multi-list-item-selected-bg: #{$action-dark};
  --fidx-color-dropdown-multi-list-item-selected-text: #{$bg-default-dark};

  --fidx-color-combobox-select-bg: #{$bg-default-dark};

  // TOGGLE VARIABLES
  --fidx-color-toggle-before-bg: #{$bg-default-dark};
  --fidx-color-toggle-before-border: #516578;
  --fidx-color-toggle-knob-bg: #{$bg-light-dark};
  --fidx-color-toggle-knob-text: #{$text-hint-dark};
  --fidx-color-toggle-knob-boxshadow: #{$black-25};
  --fidx-color-toggle-checked-bg: #{$action-dark};
  --fidx-color-toggle-knob-checked-bg: #{$bg-default-dark};
  --fidx-color-toggle-knob-checked-text: #{$white};
  --fidx-color-toggle-disabled-before-bg: #{$bg-dark-dark};
  --fidx-color-toggle-disabled-before-border: #{$bg-dark-dark};
  --fidx-color-toggle-disabled-knob-text: #{$black-55};

  // CARD VARIABLES
  --fidx-color-card-bg: #{$bg-default-dark};
  --fidx-color-card-border: #{$web-divider-dark};
  --fidx-color-card-panel-gradient: linear-gradient(180deg, #000000 42.34%, #294252 100%);
  --fidx-color-card-panel-read-more-gradient: #1a282f;

  // ALERT VARIABLES
  --fidx-color-alert-bg: #{$mu-default-dark};
  --fidx-color-alert-text: #{$text-hint-dark};
  --fidx-color-alert-bg-shade: #{$mu-shade-dark};
  --fidx-color-alert-bg-solid: #{$mu-solid-dark};
  --fidx-color-alert-header-text: #{$white};

  --fidx-color-tabs-bg: #{$bg-default-dark};
  --fidx-color-tabs-item-text: #{$text-hint-dark};
  --fidx-color-tabs-nested-text: #{$text-hint-dark};
  --fidx-color-tabs-item-selected-text: #{$default-dark};
  --fidx-color-tabs-item-selected-border: #{$action-dark};

  // GRID VARIABLES
  --fidx-color-grid-bg: #{$bg-default-dark};
  --fidx-color-grid-border: #{$web-divider-dark};
  --fidx-color-grid-toolbar-bg: #{$bg-default-dark};
  --fidx-color-grid-header-border: #{$web-divider-dark};
  --fidx-color-grid-header-icon-text: #{$white};
  --fidx-color-grid-header-icon-hl-text: #{$black};
  --fidx-color-grid-header-bg: #{$action-dark};
  --fidx-color-grid-header-hover-bg: #{$action-dark};
  --fidx-color-grid-row-alt-bg: #{$white};
  --fidx-color-grid-row-header-bg: #{$action-dark};
  --fidx-color-grid-row-header-text: #{$bg-default-dark};
  --fidx-color-grid-col-text: #{$default-dark};
  --fidx-color-grid-col-border: #{$web-divider-dark};
  --fidx-color-grid-row-hover-bg: #{$bg-dark-dark};
  --fidx-color-grid-sort-icon-text: #{$text-hint-dark};
  --fidx-color-grid-negative-value: #{$negative-dark};
  --fidx-color-grid-positive-value: #{$positive-dark};
  --fidx-color-grid-emptydata-text: #{$bg-contrast-default-dark};
  --fidx-color-grid-pager-text: #{$white};
  --fidx-color-grid-pager-bg: #{$bg-default-dark};
  --fidx-color-grid-pager-border: #{$web-divider-dark};
  --fidx-color-grid-paging-text: #{$action-dark};
  --fidx-color-grid-paging-bg: #{$bg-default-dark};
  --fidx-color-grid-paging-border: #{$web-divider-dark};
  --fidx-color-grid-paging-selected-text: #{$action-dark};
  --fidx-color-grid-paging-selected-bg: #{$light-dark};
  --fidx-color-grid-paging-selected-border: #{$web-divider-dark};
  --fidx-color-grid-paging-icon-text: #{$action-dark};
  --fidx-color-grid-paging-hover-bg: #{$light-dark};
  --fidx-color-grid-menu-item-selected-bg: #{$light-dark};
  --fidx-color-grid-menu-item-selected-text: #{$action-dark};
  --fidx-color-grid-checkbox-selected-border: #{$action-dark};
  --fidx-color-grid-checkbox-selected-bg: #{$action-dark};
  --fidx-color-grid-checkbox-selected-text: #{$white};
  --fidx-color-grid-button-border: #{$web-divider-dark};
  --fidx-color-grid-button-bg: #{$white};
  --fidx-color-grid-button-text: #{$action-dark};
  --fidx-color-grid-button-primary-border: #{$action-dark};
  --fidx-color-grid-button-primary-bg: #{$action-dark};
  --fidx-color-grid-button-primary-text: #{$white};
  --fidx-color-grid-button-primary-hover-border: #{$action-dark};
  --fidx-color-grid-button-primary-hover-bg: #{$action-dark};

  // LOADER VARIABLES
  --fidx-color-loader-border: #{$web-divider-dark};
  --fidx-color-loader-border-top: #{$action-dark};
  --fidx-color-loader-overlay-bg: #{$loader-overlay-bg-dark};
  --fidx-color-loader-overlay-container-bg: #{$bg-default-dark};
  --fidx-color-loader-scroll-bg: #4f5152;

  // MODEL VARIABLES
  --fidx-color-dialog-content-bg: #{$bg-default-dark};
  --fidx-color-dialog-content-text: #{$default-dark};
  --fidx-color-dialog-content-boxshadow: #{$black};
  --fidx-color-dialog-icon-triangle-text: #{$caution-dark};

  // TOAST VARIABLE
  --fidx-color-toast-success-bg: #094745;
  --fidx-color-toast-success-text: #37dbd6;
  --fidx-color-toast-caution-bg: #4b5143;
  --fidx-color-toast-caution-text: #{$caution-dark};
  --fidx-color-toast-negative-bg: #413131;
  --fidx-color-toast-negative-text: #ff8b8b;
  --fidx-color-toast-information-bg: #18364f;
  --fidx-color-toast-information-text: #71b9ff;
  --fidx-color-toast-spinner-animation: p-progress-spinner-color-dark;

  // SITE VARIABLES
  --fidx-color-body-bg: #{$mu-shade-dark};

  // HEADER VARIABLES
  --fidx-color-header-bg: #{$bg-default-dark};
  --fidx-color-header-nav-active-link: #{$link-active-dark};
  --fidx-color-header-user-text: #{$white};
  --fidx-color-dropdown-advisortools-text: #{$default-dark};

  // BREADCRUMB VARIABLES
  --fidx-color-bc-bg: #{$bg-default-dark};
  --fidx-color-bc-sticky-border: #{$web-divider-dark};
  --fidx-color-bc-sticky-boxshadow: #{$black-15};
  --fidx-color-bc-header: #{$white};
  --fidx-color-bc-text: #{$action-dark};
  --fidx-color-bc-alink-text: #{$action-dark};
  --fidx-color-bc-slink-text: #{$text-hint-dark};
  --fidx-color-bc-close-text: #{$text-hint-dark};

  // FOOTER VARIABLES
  --fidx-color-footer-bg: #{$bg-default-dark};
  --fidx-color-footer-text: #{$white};
  --fidx-color-footer-link-text: #{$white};
  --fidx-color-footer-divider-text: #{$black-15};

  --fidx-color-dashboard-text: #{$text-hint-dark};
  --fidx-color-dashboard-text-hover: #{$white};
  --fidx-color-dashboard-pie-stroke: #{$web-divider-dark};

  // SPECIFIC PAGE VARIABLES
  --fidx-color-home-bg: #1a394f;
  --fidx-color-home-bg-gradient: transparent;
  --fidx-color-home-bg-non-gradient: #1a394f;

  --fidx-color-pd-selection-bg: #{$bg-contrast-default-dark};
  --fidx-color-pd-selection-header-text: #{$text-hint-dark};
  --fidx-color-pd-target-bg: #{$bg-contrast-default-dark};
  --fidx-color-pd-target-text: #{$default-dark};

  --fidx-color-risk-border: #{$default-dark};
  --fidx-color-risk-tolerance-bg: #{$risk-tolerance-bg-dark};
  --fidx-color-risk-pointer: #{$negative-dark};
  --fidx-color-risk-model: #{$affirmation-dark};
  --fidx-color-risk-portfolio-bg: #{map-get(map-get($opacity-dark, 'negative'), '10')};

  --fidx-color-wb-bg-light: #{$workbench-action-light-dark};
  --fidx-color-wb-bg-dark: #{$workbench-action-dark-dark};
  --fidx-color-wb-bg-hover-light: #{$workbench-action-hover-light-dark};
  --fidx-color-wb-bg-hover-dark: #{$workbench-action-hover-dark-dark};
  --fidx-color-wb-border: #{$workbench-action-border-dark};
  --fidx-color-wb-transact: #{$workbench-transact-icon-dark};

  --fidx-color-tieredmenu-active-bg: #{$tiered-menu-active-bg-dark};

  --fidx-color-compare-grid-header-bg: #{$bg-dark-dark};
  --fidx-color-compare-allocation-bg: #{$compare-allocation-bg-dark};
  --fidx-color-compare-allocation-meter-border: #{$input-divider-dark};
  --fidx-color-compare-allocation-meter-boxshadow: #{$compare-allocation-meter-boxshadow-dark};
  --fidx-color-asset-class-color-border: #{$white};
  --fidx-color-compare-spacer-bg: #{map-get(map-get($opacity-dark, 'affirmation'), '25')};
  --fidx-color-compare-grid-row-hover-bg: #{$bg-dark-dark};

  --fidx-color-filters-tab-bg: #{$filters-tab-bg-dark};

  --fidx-color-chart-bg-1: #{$info-a-3-dark};
  --fidx-color-chart-bg-2: #{$info-b-3-dark};
  --fidx-color-chart-bg-3: #{$info-c-3-dark};
  --fidx-color-chart-bg-4: #{$info-d-3-dark};
  --fidx-color-chart-bg-5: #{$info-e-3-dark};
  --fidx-color-chart-bg-6: #{$info-f-3-dark};
  --fidx-color-chart-bg-7: #{$info-g-3-dark};
  --fidx-color-chart-bg-8: #{$info-h-3-dark};
  --fidx-color-chart-bg-9: #{$info-a-dark};
  --fidx-color-chart-bg-10: #{$info-b-dark};
  --fidx-color-chart-bg-11: #{$info-c-dark};
  --fidx-color-chart-bg-12: #{$info-d-dark};
  --fidx-color-chart-bg-13: #{$info-e-dark};
  --fidx-color-chart-bg-14: #{$info-f-dark};
  --fidx-color-chart-bg-15: #{$info-g-dark};
  --fidx-color-chart-bg-16: #{$info-h-dark};
}
